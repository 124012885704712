import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const WithdrawalModal = ({ 
    isOpen, 
    onClose, 
    formData, 
    onFormChange, 
    onSubmit,
    availableBalance = 0
  }) => {
    const [error, setError] = useState('');
  
    const handleAmountChange = (e) => {
      const amount = parseFloat(e.target.value);
      if (amount > availableBalance) {
        setError(`Amount cannot exceed available balance of ${availableBalance.toFixed(2)}`);
      } else {
        setError('');
      }
      onFormChange({ ...formData, amount: e.target.value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const amount = parseFloat(formData.amount);
      if (amount > availableBalance) {
        setError(`Amount cannot exceed available balance of ${availableBalance.toFixed(2)}`);
        return;
      }
      onSubmit(e);
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Process Withdrawal</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X size={20} />
            </button>
          </div>
  
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount
              </label>
              <input
                type="number"
                step="0.01"
                value={formData.amount}
                onChange={handleAmountChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                placeholder="Enter amount"
              />
            </div>
  
            {error && (
              <div className="mb-4 p-2 bg-red-50 text-red-600 text-sm rounded">
                {error}
              </div>
            )}
  
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                disabled={!!error || !formData.amount}
              >
                Process Withdrawal
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  export default WithdrawalModal;