
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import generalSettingsService from '../api/generalSettingsService';
import { setSettings, setLoading, setError } from '../store/settingsSlice';

export const useSettings = () => {
  const dispatch = useDispatch();
  const { settings, loading, error, initialized } = useSelector(state => state.settings);

  useEffect(() => {
    const fetchSettings = async () => {
      // Only fetch if not already initialized
      if (initialized) return;
      
      try {
        dispatch(setLoading(true));
        const response = await generalSettingsService.getSettings();
        dispatch(setSettings(response.data));
      } catch (err) {
        console.error('Error loading settings:', err);
        dispatch(setError(err));
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchSettings();
  }, [dispatch, initialized]);

  return { settings, loading, error };
};