// utils/authInterceptor.js
import { TokenManager } from '../utils/tokenManager';
import { logger } from '../../utils/logger';

export const authInterceptor = async (config) => {
  let token = null;
  let tokenType = null;
  
  // Function to check and validate token
  const validateToken = (testToken, type) => {
    if (!testToken) return null;
    if (TokenManager.isTokenExpired(testToken, type)) {
      logger.warn(`${type} token is expired`);
      return null;
    }
    return testToken;
  };

  // Get both tokens
  const adminToken = TokenManager.getStoredToken('admin');
  const memberToken = TokenManager.getStoredToken('member');

  // Ensure config.headers exists
  config.headers = config.headers || {};

  // Case 1: Explicitly requesting admin token
  if (config.useAdminToken) {
    token = validateToken(adminToken, 'admin');
    tokenType = token ? 'admin' : null;
  }
  // Case 2: Explicitly requesting member token
  else if (config.useMemberToken) {
    token = validateToken(memberToken, 'member');
    tokenType = token ? 'member' : null;
  }
  // Case 3: No explicit token type specified - try admin first, then member
  else {
    token = validateToken(adminToken, 'admin');
    if (token) {
      tokenType = 'admin';
    } else {
      token = validateToken(memberToken, 'member');
      tokenType = token ? 'member' : null;
    }
  }

  // If we found a valid token
  if (token) {
    // Check if token will expire soon
    if (TokenManager.willExpireSoon(token)) {
      logger.warn('Token will expire soon', { tokenType });
    }

    config.headers['Authorization'] = `Bearer ${token}`;
    logger.log('Token added to request', { 
      tokenType,
      url: config.url,
      method: config.method,
      hasFile: config.headers['Content-Type']?.includes('multipart/form-data')
    });
  } else {
    logger.warn('No valid token found', {
      url: config.url,
      useAdminToken: config.useAdminToken,
      useMemberToken: config.useMemberToken,
      hadAdmin: !!adminToken,
      hadMember: !!memberToken
    });
  }

  // Clean up the config by removing our custom properties
  delete config.useAdminToken;
  delete config.useMemberToken;

  return config;
};