import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';


const generalSettingsService = {
  // Cycle-related functions
  getCycles: async () => {
    try {
      const response = await axiosInstance.get('/cycles');
      return response.data;
    } catch (error) {
      handleError(error, 'fetching cycles');
    }
  },

  getCycleById: async (id) => {
    try {
      const response = await axiosInstance.get(`/cycles/${id}`
      );
      return response.data;
    } catch (error) {
      handleError(error, 'fetching cycle');
    }
  },

  createCycle: async (cycleData) => {
    try {
      const response = await axiosInstance.post('/cycles', cycleData, 
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'creating cycle');
    }
  },

  updateCycle: async (id, cycleData) => {
    try {
      const response = await axiosInstance.put(`/cycles/${id}`, cycleData, 
        { useAdminToken: true });
      return response.data;
    } catch (error) {
      handleError(error, 'updating cycle');
    }
  },

  deleteCycle: async (id) => {
    try {
      const response = await axiosInstance.delete(`/cycles/${id}`, 
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'deleting cycle');
    }
  },

  getSettings: async () => {
    try {
      const response = await axiosInstance.get('/settings');
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  uploadLogo: async (formData) => {
    const response = await axiosInstance.post(`/settings/upload-logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  },

  // Remove logo
  removeLogo: async () => {
    const response = await axiosInstance.delete(`/settings/logo`);
    return response.data;
  },

  
  // Update settings (admin)
  updateSettings: async (settings) => {
    try {
      const response = await axiosInstance.put('/settings/admin', settings, 
        { useAdminToken: true });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getEmailTemplates: () => {
    try {
      return axiosInstance.get('/settings/email-templates');
    } catch (error) {
      throw error;
    }
  },

  updateEmailTemplates: (templates) => {
    try {
      return axiosInstance.put('/settings/email-templates', { templates: templates});
    } catch (error) {
      throw error;
    }
  },

  sendTestEmail: (data) => {
    try {
      return axiosInstance.post('/settings/test-email', data);
    } catch (error) {
      throw error;
    }
  }
};

export default generalSettingsService;