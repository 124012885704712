// src/store/settingsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: {
    siteTitle: '',
    siteName: '',
    siteLogo: ''
  },
  loading: false,
  error: null,
  initialized: false
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
      state.initialized = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setSettings, setLoading, setError } = settingsSlice.actions;
export default settingsSlice.reducer;

