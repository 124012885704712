import React, { useState, useEffect } from 'react';
import { Search, Download, ChevronLeft, ChevronRight } from 'lucide-react';
import transactionsService from '../../../api/transactionsService';
import { useAlert } from '../../../hooks/useAlert';
import WithdrawalModal from './WithdrawalModal';
import Pagination from '../../common/Pagination';
import { useSelector } from 'react-redux';

const TransactionCard = ({ title, amount, count, color }) => (
  <div className="bg-white rounded-lg shadow-md p-4">
    <h3 className="text-gray-600 text-sm font-medium">{title}</h3>
    <div className={`text-2xl font-bold ${color} mt-2`}>
      ${amount.toFixed(2)}
    </div>
    <div className="text-gray-500 text-sm mt-1">
      {count} transactions
    </div>
  </div>
);

const MemberEarningsSection = () => {
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [earnings, setEarnings] = useState([]);
  const [selectedEarnings, setSelectedEarnings] = useState([]);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [withdrawalForm, setWithdrawalForm] = useState({
    amount: '',
  });
  const { member } = useSelector((state) => state.auth);
  
  const [summary, setSummary] = useState({
    total: { amount: 0, count: 0 },
    available: { amount: 0, count: 0 },
    withdrawn: { amount: 0, count: 0 },
    paid: { amount: 0, count: 0 }
  });
  
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    status: '',
    commissionId: ''
  });
  
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0
  });
  
  const [availableCommissions, setAvailableCommissions] = useState([]);

  const handlePageSizeChange = (newPageSize) => {
    setPagination(prev => ({
      ...prev,
      pageSize: newPageSize,
      pageNumber: 1
    }));
  };

  const fetchEarnings = async () => {
    setIsLoading(true);
    try {
      const data = await transactionsService.getMemberEarnings(
        filters,
        pagination.pageNumber,
        pagination.pageSize
      );
      setEarnings(data.earnings);
      setSummary(data.summary);
      setPagination(data.metadata);
      setAvailableCommissions(data.filters.availableCommissions);
    } catch (error) {
      showAlert('error', error.message || 'Failed to fetch earnings');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEarnings();
  }, [pagination.pageNumber, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const handleEarningSelection = (earningId) => {
    setSelectedEarnings(prev => {
      if (prev.includes(earningId)) {
        return prev.filter(id => id !== earningId);
      }
      return [...prev, earningId];
    });
  };

  const handleWithdrawByEarnings = async () => {
    if (selectedEarnings.length === 0) {
      showAlert('warning', 'Please select earnings to withdraw');
      return;
    }
  
    try {
      await transactionsService.withdrawEarnings(
        member.currentUser.id, // or get from context
        selectedEarnings
      );
      showAlert('success', 'Withdrawal request submitted successfully');
      setSelectedEarnings([]);
      fetchEarnings();
    } catch (error) {
      showAlert('error', error.message || 'Failed to submit withdrawal request');
    }
  };
  
  const handleWithdrawByAmount = async (e) => {
    e.preventDefault();
    try {
      if (!withdrawalForm.amount || isNaN(withdrawalForm.amount) || withdrawalForm.amount <= 0) {
        showAlert('error', 'Please enter a valid amount');
        return;
      }
  
      if (parseFloat(withdrawalForm.amount) > summary.available.amount) {
        showAlert('error', 'Withdrawal amount cannot exceed available balance');
        return;
      }
  
      await transactionsService.withdrawByAmount(
        member.currentUser.id,// or get from context
        withdrawalForm.amount
      );
      
      setShowWithdrawModal(false);
      setWithdrawalForm({ amount: '' });
      showAlert('success', 'Withdrawal request submitted successfully');
      fetchEarnings();
    } catch (error) {
      showAlert('error', error.message || 'Failed to submit withdrawal request');
    }
  };

  const getStatusStyle = (status) => {
    const styles = {
      AVAILABLE: 'bg-green-100 text-green-800',
      WITHDRAWN: 'bg-blue-100 text-blue-800',
      PAID: 'bg-purple-100 text-purple-800',
      CANCELLED: 'bg-red-100 text-red-800',
      SCHEDULED: 'bg-yellow-100 text-yellow-800'
    };
    return styles[status] || 'bg-gray-100 text-gray-800';
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <TransactionCard
          title="Total Earnings"
          amount={summary.total.amount}
          count={summary.total.count}
          color="text-gray-900"
        />
        <TransactionCard
          title="Available"
          amount={summary.available.amount}
          count={summary.available.count}
          color="text-green-600"
        />
        <TransactionCard
          title="Withdrawn"
          amount={summary.withdrawn.amount}
          count={summary.withdrawn.count}
          color="text-blue-600"
        />
        <TransactionCard
          title="Paid"
          amount={summary.paid.amount}
          count={summary.paid.count}
          color="text-purple-600"
        />
      </div>

      {/* Filters */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <form className="grid grid-cols-1 md:grid-cols-5 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <input
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <input
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">All Statuses</option>
              <option value="AVAILABLE">Available</option>
              <option value="WITHDRAWN">Withdrawn</option>
              <option value="PAID">Paid</option>
              <option value="SCHEDULED">Scheduled</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Commission Type
            </label>
            <select
              name="commissionId"
              value={filters.commissionId}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">All Commissions</option>
              {availableCommissions.map(commission => (
                <option key={commission.id} value={commission.id}>
                  {commission.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-end">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Apply Filters
            </button>
          </div>
        </form>
      </div>

      {/* Withdraw Actions */}
      {summary.available.amount > 0 && (
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleWithdrawByEarnings}
            disabled={selectedEarnings.length === 0}
            className={`${
              selectedEarnings.length > 0
                ? 'bg-blue-600 hover:bg-blue-700'
                : 'bg-gray-400 cursor-not-allowed'
            } text-white px-4 py-2 rounded-md transition-colors duration-200 flex items-center space-x-2`}
          >
            <Download size={18} />
            <span>Withdraw Selected ({selectedEarnings.length})</span>
          </button>
          <button
            onClick={() => setShowWithdrawModal(true)}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md transition-colors duration-200"
          >
            Withdraw by Amount
          </button>
        </div>
      )}

      {/* Earnings Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const availableEarnings = earnings
                        .filter(earning => earning.status === 'AVAILABLE')
                        .map(earning => earning.id);
                      setSelectedEarnings(
                        e.target.checked ? availableEarnings : []
                      );
                    }}
                    checked={
                      selectedEarnings.length > 0 &&
                      selectedEarnings.length === earnings.filter(e => e.status === 'AVAILABLE').length
                    }
                  />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Commission
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  From
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Level
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {earnings.map((earning) => (
                <tr key={earning.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {earning.status === 'AVAILABLE' && (
                      <input
                        type="checkbox"
                        checked={selectedEarnings.includes(earning.id)}
                        onChange={() => handleEarningSelection(earning.id)}
                      />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(earning.scheduledDate).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {earning.commission.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {earning.payer.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {earning.level !== null ? `Level ${earning.level}` : 'Self'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    ${earning.amount.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(earning.status)}`}>
                      {earning.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <Pagination
          pagination={pagination}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
        <WithdrawalModal 
            isOpen={showWithdrawModal}
            onClose={() => setShowWithdrawModal(false)}
            formData={withdrawalForm}
            onFormChange={setWithdrawalForm}
            onSubmit={handleWithdrawByAmount}
            availableBalance={summary.available.amount}
            />
    </div>
    
  );
}

export default MemberEarningsSection;
