// services/fileHandlingService.js
import { axiosInstance } from './config/axiosConfig';
import { handleError } from '../utils/errorHandler';

const fileHandlingService = {
  getMimeType: (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'webp': 'image/webp',
      'pdf': 'application/pdf',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    };
    return mimeTypes[ext] || 'application/octet-stream';
  },

  getFileUrl: (relativePath, type) => {
    if (!relativePath) return null;
    const cleanPath = relativePath.replace(/^uploads\//, '');
    const baseUrls = {
      'ticket': '/tickets/files',
      'payment': '/withdrawals-payments/files',
      'site': '/setting/files'
    };
    return `${baseUrls[type]}/${cleanPath}`;
  },

  getFileData: async (fileUrl, type) => {
    try {
      const cleanPath = fileUrl.replace(/^uploads\//, '');
      const baseUrls = {
        'ticket': '/tickets/files',
        'payment': '',
        'site': '/settings/files'
      };
      const url = `${baseUrls[type]}/${cleanPath}`;
      const response = await axiosInstance({
        url: url,
        method: 'GET',
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      handleError(error, 'getting file data');
      throw error;
    }
  },

  downloadFile: async (fileUrl, type) => {
    try {
      const blob = await fileHandlingService.getFileData(fileUrl, type);
      const mimeType = fileHandlingService.getMimeType(fileUrl);
      const blobWithType = new Blob([blob], { type: mimeType });
      
      const downloadUrl = window.URL.createObjectURL(blobWithType);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileUrl.split('/').pop());
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      handleError(error, 'downloading file');
    }
  },

  openFile: async (fileUrl, type) => {
    try {
      const blob = await fileHandlingService.getFileData(fileUrl, type);
      const mimeType = fileHandlingService.getMimeType(fileUrl);
      const blobWithType = new Blob([blob], { type: mimeType });
      
      const blobUrl = window.URL.createObjectURL(blobWithType);
      window.open(blobUrl, '_blank');
      setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);
    } catch (error) {
      handleError(error, 'opening file');
    }
  },

  validateFileSize: (file, maxSize = 5 * 1024 * 1024) => {
    return file.size <= maxSize;
  },

  validateFileType: (file, allowedTypes = ['image']) => {
    const fileType = file.type.split('/')[0];
    return allowedTypes.includes(fileType);
  },

  generatePreviewUrl: (file) => {
    return new Promise((resolve, reject) => {
      if (!file || !file.type.startsWith('image/')) {
        reject('Not an image file');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
};

export default fileHandlingService;