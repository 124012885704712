import React, { useState, useEffect } from 'react';
import { Search, Eye, Download } from 'lucide-react';
import PaymentDetailsModal from './PaymentDetailsModal';
import paymentsService from '../../../api/paymentsService';
import { useAlert } from '../../../hooks/useAlert';
import Pagination from '../../common/Pagination';
import fileHandlingService from '../../../api/fileHandlingService';
import { useNavigate } from 'react-router-dom';
import { p } from 'framer-motion/client';
const PaymentsSection = () => {
  const [payments, setPayments] = useState([]);
  const [summary, setSummary] = useState({
    totalAmount: 0,
    averageAmount: 0,
    totalCount: 0,
    paymentTypeDistribution: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [filters, setFilters] = useState({
    memberUsername: '',
    payTypeId: '',
    dateFrom: '',
    dateTo: '',
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  });
  const [payTypes, setPayTypes] = useState([]);

  const { showAlert } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPayments();
    fetchPayTypes();
  }, [pagination.pageNumber, pagination.pageSize, filters]);

  const fetchPayments = async () => {
    try {
      const  data  = await paymentsService.getAllPayments({
        ...filters,
        page: pagination.pageNumber,
        limit: pagination.pageSize,
      });
      setPayments(data.payments);
      setSummary(data.summary);
      setPagination(data.metadata);
    } catch (err) {
      showAlert('error', 'Failed to fetch payments');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPayTypes = async () => {
    try {
      const data = await paymentsService.getPayTypes();
      setPayTypes(data);
    } catch (err) {
      showAlert('error', 'Failed to fetch payment types');
    }
  };

  const handleShowDetails = async (paymentId) => {
    try {
      const  data  = await paymentsService.getPaymentDetails(paymentId);
      setSelectedPayment(data.data);
      setShowDetailsModal(true);
    } catch (err) {
      showAlert('error', 'Failed to fetch payment details');
    }
  };

  const handleViewEarnings = async (paymentId) => {
    try {
      navigate(`/admin/manage-earnings?paymentId=${paymentId}`);
    } catch (err) {
      showAlert('error', 'Failed to fetch payment details');
    }
  }

  const handleDownloadScreenshot = async (payment) => {
    try {
      const blob = await fileHandlingService.getFileData(payment.screenshot, 'payment');
      const mimeType = fileHandlingService.getMimeType(payment.screenshot);
      const blobWithType = new Blob([blob], { type: mimeType });
      const url = URL.createObjectURL(blobWithType);
      const a = document.createElement('a');
      a.href = url;
      a.download = payment.screenshot;
      a.click();
      URL.revokeObjectURL(url);
    } catch (err) {
      showAlert('error', 'Failed to download screenshot');
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  return (
    <>
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-6">
        <div className="bg-green-50 p-4 rounded-lg shadow">
          <h3 className="text-green-700 text-lg font-semibold">Total Amount Paid</h3>
          <p className="text-2xl font-bold text-green-800">
            {paymentsService.formatAmount(summary.totalAmount)}
          </p>
        </div>
        <div className="bg-blue-50 p-4 rounded-lg shadow">
          <h3 className="text-blue-700 text-lg font-semibold">Total Payments</h3>
          <p className="text-2xl font-bold text-blue-800">
            {summary.totalCount}
          </p>
        </div>
        <div className="bg-purple-50 p-4 rounded-lg shadow">
          <h3 className="text-purple-700 text-lg font-semibold">Average Payment</h3>
          <p className="text-2xl font-bold text-purple-800">
            {paymentsService.formatAmount(summary.averageAmount)}
          </p>
        </div>
      </div>

      {/* Filters */}
      <form onSubmit={(e) => { e.preventDefault(); fetchPayments(); }} className="p-6 bg-gray-50 border-b border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Search by username"
            value={filters.memberUsername}
            onChange={(e) => setFilters(prev => ({ ...prev, memberUsername: e.target.value }))}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
          />
          <select
            value={filters.payTypeId}
            onChange={(e) => setFilters(prev => ({ ...prev, payTypeId: e.target.value }))}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
          >
            <option value="">All Payment Types</option>
            {payTypes.map(type => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
          <input
            type="date"
            value={filters.dateFrom}
            onChange={(e) => setFilters(prev => ({ ...prev, dateFrom: e.target.value }))}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex gap-2">
            <input
              type="date"
              value={filters.dateTo}
              onChange={(e) => setFilters(prev => ({ ...prev, dateTo: e.target.value }))}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500"
            />
            <button 
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              <Search size={20} />
            </button>
          </div>
        </div>
      </form>

      {/* Payments Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Date</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Member</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Payment Type</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Reference</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {payments.map((payment) => (
              <tr key={payment.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {paymentsService.formatDate(payment.processedAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {payment.member.username}
                    </div>
                    <div className="text-sm text-gray-500">
                      {payment.member.email}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {paymentsService.formatAmount(payment.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {payment.payType.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {payment.referenceNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button
                    onClick={() => handleShowDetails(payment.id)}
                    className="text-blue-600 hover:text-blue-900 mr-3"
                    title="View Details"
                  >
                    <Eye size={20} />
                  </button>
                  {payment.screenshot && (
                    <button
                      onClick={() => handleDownloadScreenshot(payment)}
                      className="text-green-600 hover:text-green-900"
                      title="Download Screenshot"
                    >
                      <Download size={20} />
                    </button>
                  )}
                   <button 
                        onClick={() => handleViewEarnings(payment.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        View Earnings
                      </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        pagination={pagination}
        onPageChange={(page) => setPagination(prev => ({ ...prev, pageNumber: page }))}
        onPageSizeChange={(size) => setPagination(prev => ({ ...prev, pageSize: size, pageNumber: 1 }))}
      />

      <PaymentDetailsModal
        isOpen={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        payment={selectedPayment}
      />
    </>
  );
};

export default PaymentsSection;