// src/api/payTypeService.js
import {axiosInstance} from './config/axiosConfig';
const payTypeService = {
  getPayTypes: () => axiosInstance.get('/pay-types'),
  createPayType: (data) => axiosInstance.post('/pay-types/admin', data),
  updatePayType: (id, data) => axiosInstance.put(`/pay-types/admin/${id}`, data),
  deletePayType: (id) => axiosInstance.delete(`/pay-types/admin/${id}`),
  
};

export default payTypeService;