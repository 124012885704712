// src/api/utils/TokenManager.js
import { store } from '../../store';
import { clearMember, clearAdmin } from '../../store/authSlice';
import { logger } from '../../utils/logger';

export const REFRESH_THRESHOLD = 5 * 60; // 5 minutes in seconds

export class TokenManager {

  static tokenCache = {
    admin: null,
    member: null
  };
  static parseToken(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      logger.error('Error parsing token', error);
      return null;
    }
  }

  static isTokenExpired(token, tokenType) {
    if (!token) return true;

    try {
      const decoded = this.parseToken(token);
      if (!decoded) return true;

      const currentTime = Math.floor(Date.now() / 1000);
      const expired = currentTime >= decoded.exp;

      if (expired) {
        this.clearToken(tokenType);
        return true;
      }

      return false;
    } catch (error) {
      this.clearToken(tokenType);
      return true;
    }
  }


  static willExpireSoon(token) {
    if (!token) return true;
    
    const decoded = this.parseToken(token);
    if (!decoded) return true;
    
    const currentTime = Math.floor(Date.now() / 1000);
    return (decoded.exp - currentTime) < REFRESH_THRESHOLD;
  }

  static clearToken(tokenType) {
    this.tokenCache[tokenType] = null;
    localStorage.removeItem(`${tokenType}Token`);
    if (tokenType === 'admin') {
      store.dispatch(clearAdmin());
      localStorage.removeItem('role');
    } else {
      store.dispatch(clearMember());
    }
  }

  static getStoredToken(tokenType) {
    // Use cached token if available
    if (this.tokenCache[tokenType]) {
      return this.tokenCache[tokenType];
    }

    const token = localStorage.getItem(`${tokenType}Token`);
    if (token && !this.isTokenExpired(token, tokenType)) {
      this.tokenCache[tokenType] = token;
    }
    return token;
  }

  static setStoredToken(tokenType, token) {
    this.tokenCache[tokenType] = token;
    localStorage.setItem(`${tokenType}Token`, token);
  }

  static getRole() {
    return localStorage.getItem('role');
  }

  static setRole(role) {
    localStorage.setItem('role', role);
  }
}