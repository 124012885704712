import React, { useState, useEffect } from 'react';
import { Save, Image, Upload } from 'lucide-react';
import CycleManagement from './CycleManagement';
import PaytypeManagement from './PaytypeManagement';
import EmailSettings from './EmailSettings';
import generalSettingsService from '../../../api/generalSettingsService';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';
import fileHandlingService from '../../../api/fileHandlingService';
import { useLogo } from '../../../hooks/useLogo';

export default function GeneralSettings() {
  const [activeTab, setActiveTab] = useState('general');
  const [settings, setSettings] = useState({
    siteTitle: '',
    siteName: '',
    siteLogo: '',
    enableRegistration: true,
    mandatoryReferral: true,
    allowDuplicateEmail: false,
  });
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();
  const { logoUrl, loading: logoLoading, error: logoError } = useLogo(settings.siteLogo);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await generalSettingsService.getSettings();
      setSettings(response.data);
    } catch (err) {
      showAlert('error', 'Failed to load settings');
      console.error('Error loading settings:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!fileHandlingService.validateFileSize(file, 2 * 1024 * 1024)) {
      showAlert('error', 'Logo file size must be less than 2MB');
      return;
    }

    if (!fileHandlingService.validateFileType(file, ['image'])) {
      showAlert('error', 'Only image files are allowed');
      return;
    }

    try {
      setLogoFile(file);
      const formData = new FormData();
      formData.append('logo', file);

      const response = await generalSettingsService.uploadLogo(formData);
      
      setSettings(prev => ({
        ...prev,
        siteLogo: response.data.siteLogo
      }));

      showAlert('success', 'Logo uploaded successfully');
    } catch (err) {
      showAlert('error', 'Failed to upload logo');
      console.error('Error uploading logo:', err);
      setLogoFile(null);
    }
  };

  const handleLogoRemove = async () => {
    openModal(
      'Remove Logo',
      'Are you sure you want to remove the current logo?',
      async () => {
        try {
          await generalSettingsService.removeLogo();
          setSettings(prev => ({
            ...prev,
            siteLogo: null
          }));
          setLogoFile(null);
          showAlert('success', 'Logo removed successfully');
        } catch (err) {
          showAlert('error', 'Failed to remove logo');
          console.error('Error removing logo:', err);
        }
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    openModal(
      'Confirm Settings Update',
      'Are you sure you want to update the system settings? This will affect the entire application.',
      async () => {
        try {
          setSaving(true);
          await generalSettingsService.updateSettings(settings);
          showAlert('success', 'Settings updated successfully');
        } catch (err) {
          showAlert('error', 'Failed to update settings');
          console.error('Error updating settings:', err);
        } finally {
          setSaving(false);
        }
      }
    );
  };

  if (loading) {
    return (
      <div className="bg-white shadow rounded-lg p-6 max-w-4xl mx-auto">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
        </div>
      </div>
    );
  }

  const renderLogoPreview = () => {
    if (logoLoading) {
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-800"></div>
        </div>
      );
    }

    if (logoError) {
      return (
        <div className="absolute inset-0 flex items-center justify-center bg-red-50 text-red-500">
          <span className="text-xs">Error</span>
        </div>
      );
    }

    if (logoUrl) {
      return (
        <img
          src={logoUrl}
          alt="Site Logo Preview"
          className="object-contain w-full h-full"
        />
      );
    }

    return (
      <div className="absolute inset-0 flex items-center justify-center bg-gray-50 text-gray-400">
        <span className="text-xs">No Logo</span>
      </div>
    );
  };

  return (
    <div className="flex space-x-6">
      {/* Left sidebar */}
      <div className="w-64 space-y-2">
        <button
          onClick={() => setActiveTab('general')}
          className={`w-full text-left px-4 py-2 rounded ${
            activeTab === 'general' 
              ? 'bg-blue-100 text-blue-700' 
              : 'hover:bg-gray-100'
          }`}
        >
          General Settings
        </button>
        <button
          onClick={() => setActiveTab('email')}
          className={`w-full text-left px-4 py-2 rounded ${
            activeTab === 'email' 
              ? 'bg-blue-100 text-blue-700' 
              : 'hover:bg-gray-100'
          }`}
        >
          Email Settings
        </button>
      </div>

      {/* Main content */}
      <div className="flex-1">
        {activeTab === 'general' ? (
          <div className="space-y-6">
            <div className="bg-white shadow rounded-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">System Settings</h2>
                <button
                  onClick={handleSubmit}
                  disabled={saving}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300 flex items-center"
                >
                  <Save size={18} className="mr-2" />
                  {saving ? 'Saving...' : 'Save Settings'}
                </button>
              </div>
              
              <form className="space-y-6">
                {/* Site Information */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium border-b pb-2">Site Information</h3>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Site Title</label>
                      <input
                        type="text"
                        name="siteTitle"
                        value={settings.siteTitle}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Site Name</label>
                      <input
                        type="text"
                        name="siteName"
                        value={settings.siteName}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                    </div>

                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">Site Logo</label>
                      <div className="mt-1 flex items-center space-x-4">
                        <div className="relative w-16 h-16 border rounded-lg overflow-hidden">
                          {renderLogoPreview()}
                        </div>
                        
                        <label className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50">
                          <Upload className="w-5 h-5 mr-2 text-gray-400" />
                          <span className="text-sm text-gray-600">
                            {logoFile ? logoFile.name : 'Upload Logo'}
                          </span>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleLogoUpload}
                            disabled={logoLoading}
                          />
                        </label>
                        
                        {logoUrl && (
                          <button
                            onClick={handleLogoRemove}
                            className="px-3 py-2 text-sm text-red-600 hover:text-red-700"
                            type="button"
                            disabled={logoLoading}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                      
                      <p className="mt-1 text-sm text-gray-500">
                        Recommended: PNG, JPG or SVG (max. 2MB)
                      </p>
                    </div>
                  </div>
                </div>

                {/* Registration Settings */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium border-b pb-2">Registration Settings</h3>
                  
                  <div className="space-y-3">
                    <div className="flex items-center bg-gray-100 p-2 rounded">
                      <input
                        type="checkbox"
                        name="enableRegistration"
                        checked={settings.enableRegistration}
                        onChange={handleChange}
                        className="h-4 w-4 text-blue-600 rounded border-gray-300"
                      />
                      <label className="ml-2 block text-sm text-gray-700">
                        Enable Registration
                      </label>
                    </div>

                    <div className="flex items-center bg-gray-100 p-2 rounded">
                      <input
                        type="checkbox"
                        name="mandatoryReferral"
                        checked={settings.mandatoryReferral}
                        onChange={handleChange}
                        className="h-4 w-4 text-blue-600 rounded border-gray-300"
                      />
                      <label className="ml-2 block text-sm text-gray-700">
                        Mandatory Referral Link
                      </label>
                    </div>

                    <div className="flex items-center bg-gray-100 p-2 rounded">
                      <input
                        type="checkbox"
                        name="allowDuplicateEmail"
                        checked={settings.allowDuplicateEmail}
                        onChange={handleChange}
                        className="h-4 w-4 text-blue-600 rounded border-gray-300"
                      />
                      <label className="ml-2 block text-sm text-gray-700">
                        Allow Duplicate Email
                      </label>
                    </div>
                  </div>
                </div>
              </form>
              <PaytypeManagement />
            </div>

            <CycleManagement />
          </div>
        ) : (
          <EmailSettings />
        )}
      </div>
    </div>
  );
}