// MemberWithdrawalsSection.js
import React, { useState, useEffect } from 'react';
import { Search, ArrowUp, ArrowDown, Clock, DollarSign, CheckCircle, XCircle } from 'lucide-react';
import transactionsService from '../../../api/transactionsService';
import { useAlert } from '../../../hooks/useAlert';
import Pagination from '../../common/Pagination';

const StatCard = ({ icon: Icon, title, amount, count, bgColor, textColor, iconColor }) => (
  <div className={`${bgColor} p-4 rounded-lg shadow`}>
    <div className="flex items-center">
      <Icon className={`h-8 w-8 ${iconColor} mr-3`} />
      <div>
        <h3 className={`${textColor} text-lg font-semibold`}>{title}</h3>
        <p className={`text-2xl font-bold ${textColor}`}>
          ${amount.toFixed(2)}
        </p>
        <p className={`text-sm ${textColor} opacity-80`}>
          {count} {count === 1 ? 'withdrawal' : 'withdrawals'}
        </p>
      </div>
    </div>
  </div>
);

const MemberWithdrawals = () => {
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [withdrawals, setWithdrawals] = useState([]);
  const [sortDirection, setSortDirection] = useState('desc');
  const [stats, setStats] = useState({
    PENDING: { amount: 0, count: 0 },
    PROCESSING: { amount: 0, count: 0 },
    COMPLETED: { amount: 0, count: 0 },
    REJECTED: { amount: 0, count: 0 }
  });
  
  const [filters, setFilters] = useState({
    memberId: '',
    status: '',
    dateFrom: '',
    dateTo: ''
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const fetchWithdrawals = async () => {
    setIsLoading(true);
    try {
      const data = await transactionsService.getMemberWithdrawals({
        ...filters,
        page: pagination.pageNumber,
        limit: pagination.pageSize,
        sortDirection
      });
      setWithdrawals(data.withdrawals);
      setStats(data.stats);
      setPagination(data.metadata);
    } catch (error) {
      showAlert('error', error.message || 'Failed to fetch withdrawals');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, [pagination.pageNumber, pagination.pageSize, sortDirection, filters]);

  const handleDateSort = () => {
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination(prev => ({
      ...prev,
      pageSize: newPageSize,
      pageNumber: 1
    }));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusStyle = (status) => {
    const styles = {
      PENDING: 'bg-yellow-100 text-yellow-800',
      PROCESSING: 'bg-blue-100 text-blue-800',
      COMPLETED: 'bg-green-100 text-green-800',
      REJECTED: 'bg-red-100 text-red-800'
    };
    return styles[status] || 'bg-gray-100 text-gray-800';
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <StatCard
          icon={Clock}
          title="Pending"
          amount={stats.PENDING.amount}
          count={stats.PENDING.count}
          bgColor="bg-yellow-50"
          textColor="text-yellow-700"
          iconColor="text-yellow-500"
        />
        <StatCard
          icon={DollarSign}
          title="Processing"
          amount={stats.PROCESSING.amount}
          count={stats.PROCESSING.count}
          bgColor="bg-blue-50"
          textColor="text-blue-700"
          iconColor="text-blue-500"
        />
        <StatCard
          icon={CheckCircle}
          title="Completed"
          amount={stats.COMPLETED.amount}
          count={stats.COMPLETED.count}
          bgColor="bg-green-50"
          textColor="text-green-700"
          iconColor="text-green-500"
        />
        <StatCard
          icon={XCircle}
          title="Rejected"
          amount={stats.REJECTED.amount}
          count={stats.REJECTED.count}
          bgColor="bg-red-50"
          textColor="text-red-700"
          iconColor="text-red-500"
        />
      </div>

      {/* Filters */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">All Statuses</option>
              <option value="PENDING">Pending</option>
              <option value="PROCESSING">Processing</option>
              <option value="COMPLETED">Completed</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              From Date
            </label>
            <input
              type="date"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              To Date
            </label>
            <input
              type="date"
              name="dateTo"
              value={filters.dateTo}
              onChange={handleFilterChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-end">
            <button
              onClick={fetchWithdrawals}
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <Search size={20} className="inline mr-2" />
              Search
            </button>
          </div>
        </div>
      </div>

      {/* Withdrawals Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  onClick={handleDateSort}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  <div className="flex items-center space-x-1">
                    <span>Request Date</span>
                    {sortDirection === 'asc' ? 
                      <ArrowUp size={14} /> : 
                      <ArrowDown size={14} />
                    }
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Process Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Earnings Count
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {withdrawals.map((withdrawal) => (
                <tr key={withdrawal.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatDate(withdrawal.date)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    ${withdrawal.amount.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(withdrawal.status)}`}>
                      {withdrawal.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {withdrawal.processedDate ? formatDate(withdrawal.processedDate) : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {withdrawal.earningsCount} earnings
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          pagination={pagination}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default MemberWithdrawals;