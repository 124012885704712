import React, { useState, useEffect } from 'react';
import { Mail, Loader2, Save } from 'lucide-react';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';
import generalSettingsService from '../../../api/generalSettingsService';

export default function EmailSettings() {
  const [settings, setSettings] = useState({
    senderEmail: '',
    fromName: '',
    smtpHost: '',
    smtpPort: '',
    smtpSecure: false,
    smtpUser: '',
    smtpPassword: ''
  });
  
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [testEmailStatus, setTestEmailStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();

  useEffect(() => {
    fetchEmailData();
  }, []);

  const fetchEmailData = async () => {
    try {
      setLoading(true);
      const [settingsResponse, templatesResponse] = await Promise.all([
        generalSettingsService.getSettings(),
        generalSettingsService.getEmailTemplates()
      ]);
      
      setSettings({
        senderEmail: settingsResponse.data.senderEmail || '',
        fromName: settingsResponse.data.fromName || '',
        smtpHost: settingsResponse.data.smtpHost || '',
        smtpPort: settingsResponse.data.smtpPort || '',
        smtpSecure: settingsResponse.data.smtpSecure || false,
        smtpUser: settingsResponse.data.smtpUser || '',
        smtpPassword: settingsResponse.data.smtpPassword || ''
      });
      
      setEmailTemplates(templatesResponse.data.data);
    } catch (err) {
      showAlert('error', 'Failed to load email settings');
      console.error('Error loading email settings:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTemplateChange = (templateId, field, value) => {
    setEmailTemplates(prev =>
      prev.map(template =>
        template.id === templateId
          ? { ...template, [field]: value }
          : template
      )
    );
  };

  const sendTestEmail = async () => {
    setTestEmailStatus('sending');
    try {
      await generalSettingsService.sendTestEmail({ email: settings.senderEmail });
      setTestEmailStatus('success');
      showAlert('success', 'Test email sent successfully');
    } catch (err) {
      setTestEmailStatus('error');
      showAlert('error', 'Failed to send test email');
    } finally {
      setTimeout(() => setTestEmailStatus(null), 3000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    openModal(
      'Confirm Email Settings Update',
      'Are you sure you want to update the email settings? This will affect all system emails.',
      async () => {
        try {
          setSaving(true);
          await Promise.all([
            generalSettingsService.updateSettings(settings),
            generalSettingsService.updateEmailTemplates(emailTemplates)
          ]);
          showAlert('success', 'Email settings updated successfully');
        } catch (err) {
          showAlert('error', 'Failed to update email settings');
          console.error('Error updating email settings:', err);
        } finally {
          setSaving(false);
        }
      }
    );
  };

  if (loading) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Email Settings</h2>
        <button
          onClick={handleSubmit}
          disabled={saving}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300 flex items-center"
        >
          <Save size={18} className="mr-2" />
          {saving ? 'Saving...' : 'Save Settings'}
        </button>
      </div>

      <form className="space-y-6">
        {/* Basic Email Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium border-b pb-2">Basic Settings</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Sender Email</label>
              <input
                type="email"
                name="senderEmail"
                value={settings.senderEmail}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">From Name</label>
              <input
                type="text"
                name="fromName"
                value={settings.fromName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </div>

        {/* SMTP Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium border-b pb-2">SMTP Configuration</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">SMTP Host</label>
              <input
                type="text"
                name="smtpHost"
                value={settings.smtpHost}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">SMTP Port</label>
              <input
                type="number"
                name="smtpPort"
                value={settings.smtpPort}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">SMTP Username</label>
              <input
                type="text"
                name="smtpUser"
                value={settings.smtpUser}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">SMTP Password</label>
              <input
                type="password"
                name="smtpPassword"
                value={settings.smtpPassword}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div className="md:col-span-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="smtpSecure"
                  checked={settings.smtpSecure}
                  onChange={handleChange}
                  className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <span className="text-sm font-medium text-gray-700">Use SSL/TLS</span>
              </label>
            </div>
          </div>

          <button
            type="button"
            onClick={sendTestEmail}
            disabled={testEmailStatus === 'sending'}
            className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 transition-colors duration-300 flex items-center"
          >
            <Mail size={18} className="mr-2" />
            {testEmailStatus === 'sending' ? (
              <>
                <span>Sending Test...</span>
                <Loader2 className="ml-2 h-4 w-4 animate-spin" />
              </>
            ) : 'Send Test Email'}
          </button>
        </div>

        {/* Email Templates */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium border-b pb-2">Email Templates</h3>
          
          <div className="space-y-4">
            {emailTemplates.map(template => (
              <div key={template.id} className="border rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <h5 className="font-medium">
                    {template.type.replace(/_/g, ' ')}
                  </h5>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={template.isActive}
                      onChange={(e) => handleTemplateChange(template.id, 'isActive', e.target.checked)}
                      className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <span className="text-sm text-gray-600">Active</span>
                  </label>
                </div>

                <div className="space-y-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Subject
                    </label>
                    <input
                      type="text"
                      value={template.subject}
                      onChange={(e) => handleTemplateChange(template.id, 'subject', e.target.value)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Content
                    </label>
                    <textarea
                      value={template.content}
                      onChange={(e) => handleTemplateChange(template.id, 'content', e.target.value)}
                      rows={8}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-mono text-sm"
                    />
                  </div>

                  <div className="text-sm text-gray-500">
                    Available variables: 
                    <code className="mx-1 px-1 py-0.5 bg-gray-100 rounded">
                      {'{'}{'{'} username {'}'}{'}'},
                      {'{'}{'{'} siteName {'}'}{'}'},
                      {'{'}{'{'} siteTitle {'}'}{'}'}
                      {template.type === 'WELCOME' && ', {{loginUrl}}'}
                      {template.type === 'PASSWORD_RESET' && ', {{resetUrl}}'}
                      {template.type === 'ACTIVATION' && ', {{dashboardUrl}}'}
                    </code>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
}