// utils/earningsStatusUtils.js

export const ALLOWED_TRANSITIONS = {
    'SCHEDULED': ['AVAILABLE', 'CANCELLED'],
    'AVAILABLE': ['ON_HOLD', 'WITHDRAWN', 'CANCELLED'],
    'ON_HOLD': ['AVAILABLE', 'WITHDRAWN', 'DEDUCTED'],
    'WITHDRAWN': [ 'PAID'], // No transitions allowed from WITHDRAWN
    'DEDUCTED': [], // No transitions allowed from DEDUCTED
    'CANCELLED': [],
    'PAID': [], 
  };
  
  export const validateStatusTransition = (currentStatus, newStatus, scheduledDate = null) => {
    // If the current status doesn't have any allowed transitions, return false
    if (!ALLOWED_TRANSITIONS[currentStatus]) {
      return {
        isValid: false,
        reason: `Invalid current status: ${currentStatus}`
      };
    }
  
    // Check if the new status is in the allowed transitions
    const isAllowed = ALLOWED_TRANSITIONS[currentStatus].includes(newStatus);
    if (!isAllowed) {
      return {
        isValid: false,
        reason: `Cannot change status from ${currentStatus} to ${newStatus}`
      };
    }
  
    // If transitioning to AVAILABLE, check if the scheduled date is in the past
    if (newStatus === 'AVAILABLE' && scheduledDate) {
      const now = new Date();
      const scheduled = new Date(scheduledDate);
      if (scheduled > now) {
        return {
          isValid: false,
          reason: 'Cannot make earning available before scheduled date'
        };
      }
    }
  
    return {
      isValid: true,
      reason: null
    };
  };
  
  export const getStatusTransitionError = (earnings, newStatus) => {
    let invalidTransitions = [];
  
    for (const earning of earnings) {
      const validation = validateStatusTransition(earning.status, newStatus, earning.scheduledDate);
      if (!validation.isValid) {
        invalidTransitions.push({
          earningId: earning.id,
          currentStatus: earning.status,
          reason: validation.reason
        });
      }
    }
  
    if (invalidTransitions.length > 0) {
      return {
        hasError: true,
        message: `Cannot update some earnings: ${invalidTransitions.length} invalid transitions`,
        details: invalidTransitions
      };
    }
  
    return {
      hasError: false,
      message: null,
      details: []
    };
  };
  
  export const getStatusDescription = (status) => {
    const descriptions = {
      'SCHEDULED': 'Earnings that are scheduled for future dates',
      'AVAILABLE': 'Earnings that are ready to be withdrawn',
      'ON_HOLD': 'Earnings that are temporarily suspended',
      'PAID': 'Earnings that have been paid',
      'WITHDRAWN': 'Earnings that have been withdrawn',
      'DEDUCTED': 'Earnings that have been deducted',
      'CANCELLED': 'Earnings that have been cancelled',
    };
  
    return descriptions[status] || status;
  };