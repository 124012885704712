import React, { useState } from 'react';
import EarningsSection from './EarningsSection';
import WithdrawalsSection from './WithdrawalsSection';
import PaymentsSection from './PaymentsSection';
import { Wallet, ArrowUpCircle, DollarSign } from 'lucide-react';

const ManageTransactions = () => {
  const [activeTab, setActiveTab] = useState('earnings');

  const tabs = [
    {
      id: 'earnings',
      label: 'Earnings',
      icon: DollarSign
    },
    {
      id: 'withdrawals',
      label: 'Withdrawals',
      icon: ArrowUpCircle
    },
    {
      id: 'payments',
      label: 'Payments',
      icon: Wallet
    }
  ];

  const renderTabs = () => (
    <div className="border-b border-gray-200">
      <nav className="flex space-x-8 px-6" aria-label="Tabs">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2
              ${activeTab === tab.id
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <tab.icon size={16} />
            {tab.label}
          </button>
        ))}
      </nav>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'earnings':
        return <EarningsSection />;
      case 'withdrawals':
        return <WithdrawalsSection />;
      case 'payments':
        return <PaymentsSection />;
      default:
        return <EarningsSection />;
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-indigo-600">
        <h2 className="text-2xl font-bold text-white">Manage Transactions</h2>
      </div>

      {renderTabs()}
      {renderContent()}
    </div>
  );
};

export default ManageTransactions;