import React, { useState, useEffect } from 'react';
import paymentsService from '../../../api/paymentsService';
import fileHandlingService from '../../../api/fileHandlingService';
import { X, Maximize, Download } from 'lucide-react';


const PaymentDetailsModal = ({ isOpen, onClose, payment }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (payment?.screenshot) {
      loadScreenshot();
    }
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [payment]);

  const loadScreenshot = async () => {
    setLoading(true);
    setError(null);
    try {
      const blob = await fileHandlingService.getFileData(payment.screenshot, 'payment');
      const mimeType = fileHandlingService.getMimeType(payment.screenshot);
      const blobWithType = new Blob([blob], { type: mimeType });
      const url = URL.createObjectURL(blobWithType);
      setPreviewUrl(url);
    } catch (err) {
      setError('Failed to load screenshot');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen || !payment) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl my-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Payment Details</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X size={20} />
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">Date</label>
            <p className="text-gray-900">{paymentsService.formatDate(payment.processedAt)}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Amount</label>
            <p className="text-gray-900">{paymentsService.formatAmount(payment.amount)}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Member</label>
            <p className="text-gray-900">{payment.member.username}</p>
            <p className="text-sm text-gray-500">{payment.member.email}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Payment Type</label>
            <p className="text-gray-900">{payment.payType.name}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Account/Wallet</label>
            <p className="text-gray-900">{payment.accountNumber}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Bank/Network</label>
            <p className="text-gray-900">{payment.bankOrNetwork}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Currency/Token</label>
            <p className="text-gray-900">{payment.currency}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Reference Number</label>
            <p className="text-gray-900">{payment.referenceNumber}</p>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600">Notes</label>
            <p className="text-gray-900">{payment.notes || 'No notes provided'}</p>
          </div>
        </div>

        {payment.screenshot && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Payment Screenshot
            </label>
            {loading ? (
              <div className="flex items-center justify-center h-48 bg-gray-100 rounded">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center h-48 bg-gray-100 rounded text-red-500">
                {error}
              </div>
            ) : (
              <div className="relative group">
                <div className="max-h-96 overflow-hidden">
                  <img
                    src={previewUrl}
                    alt="Payment Screenshot"
                    className="max-w-full h-auto rounded-lg border border-gray-200 cursor-pointer hover:opacity-90 transition-opacity object-contain"
                    onClick={() => fileHandlingService.openFile(payment.screenshot, 'payment')}
                  />
                </div>
                <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      fileHandlingService.openFile(payment.screenshot, 'payment');
                    }}
                    className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                    title="Open in new tab"
                  >
                    <Maximize size={16} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      fileHandlingService.downloadFile(payment.screenshot, 'payment');
                    }}
                    className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                    title="Download"
                  >
                    <Download size={16} />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsModal;