// services/reportPaymentsService.js
import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';


const ticketsService = {
  
    getTickets: async (params = {}) => {
        try {
            const queryParams = new URLSearchParams();
            
            // Add pagination params
            if (params.page) queryParams.append('page', params.page);
            if (params.limit) queryParams.append('limit', params.limit);
            
            // Add filter params
            if (params.status) queryParams.append('status', params.status);
            
            // Handle ticket type (can be string or array)
            if (params.type) {
                if (Array.isArray(params.type)) {
                    params.type.forEach(type => queryParams.append('type', type));
                } else {
                    queryParams.append('type', params.type);
                }
            }
            
            if (params.memberUsername) queryParams.append('memberUsername', params.memberUsername);
            if (params.dateFrom) queryParams.append('dateFrom', params.dateFrom);
            if (params.dateTo) queryParams.append('dateTo', params.dateTo);

            const response = await axiosInstance.get(
                `/tickets/admin?${queryParams.toString()}`,
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'fetching tickets');
        }
    },

    getMemberTickets: async (params = {}) => {
        try {
            const queryParams = new URLSearchParams();
            
            // Add pagination params
            if (params.page) queryParams.append('page', params.page);
            if (params.limit) queryParams.append('limit', params.limit);
            
            // Add filter params
            if (params.status) queryParams.append('status', params.status);
            
            // Handle ticket type (can be string or array)
            if (params.type) {
                if (Array.isArray(params.type)) {
                    params.type.forEach(type => queryParams.append('type', type));
                } else {
                    queryParams.append('type', params.type);
                }
            }
            
            if (params.dateFrom) queryParams.append('dateFrom', params.dateFrom);
            if (params.dateTo) queryParams.append('dateTo', params.dateTo);

            const response = await axiosInstance.get(
                `/tickets/member?${queryParams.toString()}`
                , { useMemberToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'fetching member tickets');
        }
    },

   
    createTicket: async (ticketData) => {
        try {
          const formData = new FormData();
          
          // Add basic ticket data
          formData.append('subject', ticketData.subject);
          formData.append('content', ticketData.content);
          formData.append('type', ticketData.type);
    
          // Add optional attachment if present
          if (ticketData.attachment) {
            formData.append('attachment', ticketData.attachment);
          }
    
          const response = await axiosInstance.post(
            '/tickets',
            formData,
            
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              useMemberToken: true
            }
          );
          return response.data;
        } catch (error) {
          handleError(error, 'creating ticket');
        }
      },
    
    updateTicket: async (id, ticketData) => {
        try {
            const response = await axiosInstance.put(`/tickets/${id}`, ticketData);
            return response.data;
        } catch (error) {
            handleError(error, 'updating ticket');
        }
    },
    deleteTicket: async (id) => {
        try {
            const response = await axiosInstance.delete(`/tickets/${id}, `, { useAdminToken: true });
            return response.data;
        } catch (error) {
            handleError(error, 'deleting ticket');
        }
    },

    updateTicketStatus: async (ticketId, status) => {
        try {
            const response = await axiosInstance.patch(
                `/tickets/${ticketId}/status`,
                { status },
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'updating ticket status');
        }
    },

    addMessageMember: async (ticketId, messageData) => {
        try {
            const formData = new FormData();
            if (messageData.content) {
                formData.append('content', messageData.content);
            }
            if (messageData.attachment) {
                formData.append('attachment', messageData.attachment);
            }

            const response = await axiosInstance.post(
                `/tickets/${ticketId}/messages`,
              
                formData,
            
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', 
                    },   
                     useMemberToken: true ,
                },
              
            );
            return response.data;
        } catch (error) {
            handleError(error, 'sending message');
        }
    },

    addMessageAdmin: async (ticketId, messageData) => {
        try {
            const formData = new FormData();
            if (messageData.content) {
                formData.append('content', messageData.content);
            }
            if (messageData.attachment) {
                formData.append('attachment', messageData.attachment);
            }

            const response = await axiosInstance.post(
                `/tickets/${ticketId}/messages`,
                formData,
                {
                    useAdminToken: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'sending message');
        }
    },

    getTicket: async (ticketId) => {
        try {
            const response = await axiosInstance.get(`/tickets/${ticketId}`);
            return response.data;
        } catch (error) {
            handleError(error, 'fetching ticket details');
        }
    },

   
};

export default ticketsService