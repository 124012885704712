import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';

const transactionsService = {

  getMemberEarnings: async (filters = {}, pageNumber = 1, pageSize = 10) => {
    try {
      const queryParams = new URLSearchParams({
        ...filters,
        pageNumber,
        pageSize
      });

      const response = await axiosInstance.get(`/transactions/member-earnings?${queryParams}`, 
        { useMemberToken: true } );
      return response.data;
    } catch (error) {
      handleError(error, 'fetching member earnings');
    }
  },
  // Admin functions
  getAllEarnings: async (params = {}) => {
    try {
      const {
        page = 1,
        limit = 10,
        status,
        memberUsername,
        payerUsername,
      } = params;

      const response = await axiosInstance.get('/transactions/earnings', {
        params: {
          page,
          limit,
          status,
          memberUsername,
          payerUsername,
        },
        useAdminToken: true,
      });
      return response.data;
    } catch (error) {
      handleError(error, 'fetching all earnings');
    }
  },

  getMemberWithdrawals: async ({ page = 1, limit = 10 } = {}) => {
    try {
      const response = await axiosInstance.get(`/withdrawals/member-withdrawals`, {
        params: {
          page,
          limit
        }, 
        useMemberToken: true
      });
  
      // Transform the data to match the component's expected structure
      const { data: withdrawals, metadata } = response.data;
      
      // Calculate stats from the withdrawals data
      const stats = withdrawals.reduce((acc, withdrawal) => {
        if (!acc[withdrawal.status]) {
          acc[withdrawal.status] = { amount: 0, count: 0 };
        }
        acc[withdrawal.status].amount += withdrawal.amount;
        acc[withdrawal.status].count += 1;
        return acc;
      }, {
        PENDING: { amount: 0, count: 0 },
        PROCESSING: { amount: 0, count: 0 },
        COMPLETED: { amount: 0, count: 0 },
        REJECTED: { amount: 0, count: 0 }
      });
  
      // Add earnings count to each withdrawal
      const transformedWithdrawals = withdrawals.map(withdrawal => ({
        ...withdrawal,
        earningsCount: withdrawal.earnings.length,
        // Format dates if needed
        date: new Date(withdrawal.date),
        processedDate: withdrawal.processedDate ? new Date(withdrawal.processedDate) : null
      }));
  
      return {
        withdrawals: transformedWithdrawals,
        stats,
        metadata: {
          pageNumber: metadata.page,
          pageSize: metadata.limit,
          totalPages: metadata.totalPages,
          totalCount: metadata.total,
          hasNextPage: metadata.page < metadata.totalPages,
          hasPreviousPage: metadata.page > 1
        }
      };
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch withdrawal history');
    }
  },


  withdrawEarnings: async (memberId, earningIds) => {
    try {
      const response = await axiosInstance.post('/withdrawals/withdraw', {
        memberId,
        earningIds
      });
  
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to process withdrawal');
      }
  
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Failed to process withdrawal');
    }
  },
  
  withdrawByAmount: async (memberId, amount) => {
    try {
      const response = await axiosInstance.post('/withdrawals/withdraw-amount', {
        memberId,
        amount: parseFloat(amount)
      });
  
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to process withdrawal');
      }
  
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Failed to process withdrawal');
    }
  },

  // Helper function to format currency
  formatAmount: (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  },

  // Helper function to format date
  formatDate: (date) => {
    const formattedDate = new Date(date 
    ).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC',
    });

    return formattedDate;
  },

  // Helper function to get status badge color
  getStatusColor: (status) => {
    const colors = {
      SCHEDULED: 'gray',
      AVAILABLE: 'green',
      WITHDRAWN: 'blue',
      PAID: 'purple',
      CANCELLED: 'red',
    };
    return colors[status] || 'gray';
  },

  // Helper function to summarize earnings
  summarizeEarnings: (earnings) => {
    return earnings.reduce((summary, earning) => {
      return {
        totalAmount: summary.totalAmount + earning.amount,
        byStatus: {
          ...summary.byStatus,
          [earning.status]: (summary.byStatus[earning.status] || 0) + earning.amount,
        },
      };
    }, {
      totalAmount: 0,
      byStatus: {},
    });
  },

  // Helper function to group earnings by date
  groupEarningsByDate: (earnings) => {
    return earnings.reduce((groups, earning) => {
      const date = new Date(earning.scheduledDate).toLocaleDateString(
        'en-US',
        { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }
      );
      return {
        ...groups,
        [date]: [...(groups[date] || []), earning],
      };
    }, {});
  },

  // Helper function to group earnings by commission
  groupEarningsByCommission: (earnings) => {
    return earnings.reduce((groups, earning) => {
      const commissionName = earning.commission?.name || 'Unknown';
      return {
        ...groups,
        [commissionName]: [...(groups[commissionName] || []), earning],
      };
    }, {});
  },
};

export default transactionsService;