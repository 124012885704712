import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { X } from 'lucide-react';
import { useSettings } from '../../hooks/useSettings';
import { useLogo } from '../../hooks/useLogo';
import authService from '../../api/auth';
import payPlansService from '../../api/payPlansService';
import generalSettingsService from '../../api/generalSettingsService';
import { createPersonalInfo, validatePersonalInfo } from '../../models/personalInfo';
import { useAlert } from '../../hooks/useAlert';

function Registration() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    payPlanId: '',
    selectedCommissionId: '',
  });
  
  const [error, setError] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [payPlans, setPayPlans] = useState([]);
  const [groupedCommissions, setGroupedCommissions] = useState([]);
  const [generalSettings, setGeneralSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { showAlert } = useAlert();
  const { settings } = useSettings();
  const { logoUrl, loading: logoLoading } = useLogo(settings.siteLogo);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const settingsResponse = await generalSettingsService.getSettings();
        setGeneralSettings(settingsResponse.data);

        const searchParams = new URLSearchParams(location.search);
        const ref = searchParams.get('ref');
        if (ref) {
          setReferredBy(ref);
        }

        if (settingsResponse.data.enableRegistration && 
            (!settingsResponse.data.mandatoryReferral || ref)) {
          await fetchPublicPayPlans();
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load registration settings. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [location]);

  const fetchPublicPayPlans = async () => {
    try {
      const plans = await payPlansService.getPublicPayPlans();
      setPayPlans(plans);
    } catch (error) {
      console.error('Error fetching pay plans:', error);
      setError('Failed to fetch pay plans. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'payPlanId') {
      fetchGroupedCommissions(value);
    }
  };

  const fetchGroupedCommissions = async (payPlanId) => {
    try {
      const commissions = await payPlansService.getGroupedCommissions(payPlanId);
      setGroupedCommissions(commissions);
      if (commissions.length > 0) {
        setFormData(prev => ({ ...prev, selectedCommissionId: commissions[0].id.toString() }));
      } else {
        setFormData(prev => ({ ...prev, selectedCommissionId: '' }));
      }
    } catch (error) {
      console.error('Error fetching commissions:', error);
      setError('Failed to fetch commissions. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    setError('');
    setIsSubmitting(true);
    
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      setIsSubmitting(false);
      return;
    }
    
    try {
      const personalInfo = createPersonalInfo({
        firstName: formData.firstName,
        lastName: formData.lastName,
      });

      const personalInfoErrors = validatePersonalInfo(personalInfo);
      if (personalInfoErrors) {
        setError('Invalid personal information');
        console.error(personalInfoErrors);
        setIsSubmitting(false);
        return;
      }
      
      const registrationData = {
        ...formData,
        personalInfo,
        referredBy
      };
      
      const response = await authService.register(registrationData);
      console.log('Registration successful', response.data);
      await authService.login(formData.username, formData.password);
      showAlert('success', 'Welcome OnBoard!');
      
      navigate('/member/dashboard');
    } catch (error) {
      setError('Registration failed. Please try again.');
      console.error('Error during registration:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!generalSettings?.enableRegistration) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg text-center">
          <div className="flex flex-col items-center">
            <div className="mb-4 h-32 flex items-center justify-center">
              {logoLoading ? (
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              ) : logoUrl ? (
                <img 
                  src={logoUrl}
                  alt={settings.siteName || 'Site Logo'} 
                  className="max-h-full w-auto object-contain"
                />
              ) : null}
            </div>
          </div>
          <h3 className="text-2xl font-bold text-red-600 mb-4">Registration Unavailable</h3>
          <p className="text-gray-600 mb-4">
            Registration is currently disabled. Please contact the administrator for more information.
          </p>
          <Link to="/login" className="text-blue-600 hover:text-blue-500">
            Return to Login
          </Link>
        </div>
      </div>
    );
  }

  if (generalSettings?.mandatoryReferral && !referredBy) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-xl shadow-lg text-center">
          <div className="flex flex-col items-center">
            <div className="mb-4 h-32 flex items-center justify-center">
              {logoLoading ? (
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
              ) : logoUrl ? (
                <img 
                  src={logoUrl}
                  alt={settings.siteName || 'Site Logo'} 
                  className="max-h-full w-auto object-contain"
                />
              ) : null}
            </div>
          </div>
          <h3 className="text-2xl font-bold text-yellow-600 mb-4">Referral Required</h3>
          <p className="text-gray-600 mb-4">
            A referral is required to register. Please use a valid referral link to continue.
          </p>
          <Link to="/login" className="text-blue-600 hover:text-blue-500">
            Return to Login
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl w-full space-y-8 bg-white p-8 rounded-xl shadow-lg">
        {/* Logo Section */}
        <div className="flex flex-col items-center">
          <div className="mb-4 h-32 flex items-center justify-center">
            {logoLoading ? (
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            ) : logoUrl ? (
              <img 
                src={logoUrl}
                alt={settings.siteName || 'Site Logo'} 
                className="max-h-full w-auto object-contain"
              />
            ) : null}
          </div>
          
          <div className="text-center space-y-2">
            <h2 className="text-3xl font-extrabold text-gray-900">
              {settings.siteName || 'Create an Account'}
            </h2>
            <p className="text-sm text-gray-600">
              Join us to get started
            </p>
          </div>
        </div>

        {/* Error Alert */}
        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <X className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        {/* Referral Info */}
        {referredBy && (
          <div className="bg-green-50 border-l-4 border-green-400 p-4">
            <p className="text-sm text-green-700">
              You were referred by: {referredBy}
            </p>
          </div>
        )}

        {/* Registration Form */}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            {/* Pay Plan Selection */}
            <div>
              <label htmlFor="payPlanId" className="block text-sm font-medium text-gray-700">
                Select Pay Plan
              </label>
              <select
                id="payPlanId"
                name="payPlanId"
                value={formData.payPlanId}
                onChange={handleChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              >
                <option value="">Select a pay plan</option>
                {payPlans.map(plan => (
                  <option key={plan.id} value={plan.id}>{plan.name}</option>
                ))}
              </select>
            </div>

            {/* Commission Selection */}
            {groupedCommissions.length > 0 && (
              <div>
                <label htmlFor="selectedCommissionId" className="block text-sm font-medium text-gray-700">
                  Select Commission
                </label>
                <select
                  id="selectedCommissionId"
                  name="selectedCommissionId"
                  value={formData.selectedCommissionId}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                >
                  {groupedCommissions.map(commission => (
                    <option key={commission.id} value={commission.id}>{commission.name}</option>
                  ))}
                </select>
              </div>
            )}

            {/* Personal Information */}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <label htmlFor="firstName" className="sr-only">First Name</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="First Name"
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="lastName" className="sr-only">Last Name</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Last Name"
                  onChange={handleChange}
                  
                />
                </div>

                  <div>
                    <label htmlFor="username" className="sr-only">Username</label>
                    <input
                      id="username"
                      name="username"
                      type="text"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Email address"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="confirmPassword" className="sr-only">Confirm Password</label>
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                  </div>
                  </div>
                  </div>

                  <div>
                  <button
                  type="submit"
                  disabled={isSubmitting}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                  {isSubmitting ? (
                    <div className="flex items-center">
                      <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div>
                      Registering...
                    </div>
                  ) : (
                    'Register'
                  )}
                  </button>
                  </div>

                  <div className="text-sm text-center">
                  <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                  Already have an account? Sign in
                  </Link>
                  </div>
                  </form>
                  </div>
                  </div>
                  );
                  }

                  export default Registration;
          
