import { axiosInstance } from './config/axiosConfig';
import { TokenManager } from './utils/tokenManager';
import { setLoading, setError, setMember, setAdmin, clearError, clearMember, clearAdmin } from '../store/authSlice';
import { logger } from '../utils/logger';
import { store } from '../store';
import { isPublicRoute } from '../utils/routeUtils';

const authService = {
  async login(username, password) {
    store.dispatch(setLoading(true));

    try {
      store.dispatch(clearError('member'));
      TokenManager.clearToken('member');
      logger.log('Attempting member login', { username });

      const response = await axiosInstance.post('/auth/login',
        { username, password }
      );

      if (response.data.token && response.data.user) {
        TokenManager.setStoredToken('member', response.data.token);
        store.dispatch(setMember({
          user: response.data.user,
          token: response.data.token
        }));

        logger.log('Member login successful', {
          userId: response.data.user.id
        });
      }

      return response.data;
    } catch (error) {
      logger.error('Member login error', error);

      const errorMessage = error.response?.data?.message ||
        error.message ||
        (!error.response ? 'Server does not respond' : 'An error occurred during login');

      // Store error in Redux with type specification
      store.dispatch(setError({
        type: 'member',
        message: errorMessage
      }));

      throw error;
    } finally {
      store.dispatch(setLoading(false));
    }
  },

  async adminLogin(username, password) {
    store.dispatch(setLoading(true));

    try {
      store.dispatch(clearError('admin'));
      TokenManager.clearToken('admin');
      logger.log('Attempting admin login', { username });

      const response = await axiosInstance.post('/auth/admin-login',
        { username, password }
      );

      if (response.data.token) {
        TokenManager.setStoredToken('admin', response.data.token);
        TokenManager.setRole(response.data.role || 'MEMBER');

        store.dispatch(setAdmin({
          user: {
            ...response.data.user,
            role: response.data.role
          },
          token: response.data.token
        }));

        logger.log('Admin login successful', {
          role: response.data.role
        });
      }

      return response.data;
    } catch (error) {
      logger.error('Admin login error', error);

      const errorMessage = error.response?.data?.message ||
        error.message ||
        (!error.response ? 'Server does not respond' : 'An error occurred during admin login');

      // Store error in Redux with type specification
      store.dispatch(setError({
        type: 'admin',
        message: errorMessage
      }));

      throw error;
    } finally {
      store.dispatch(setLoading(false));
    }
  },

  async logout(isAdmin = false) {
    const currentPath = window.location.pathname;

    logger.log('Logout initiated', {
      currentPath,
      isAdmin
    });

    TokenManager.clearToken(isAdmin ? 'admin' : 'member');
    store.dispatch(isAdmin ? clearAdmin() : clearMember());

    const loginPath = isAdmin ? '/admin-login' : '/login';
    logger.log('Redirecting to', { loginPath });
    window.location.href = loginPath;
  },

  async initializeAuth(currentPath) {
    // Skip initialization for public routes
    if (isPublicRoute(currentPath)) {
      return { initialized: true };
    }

    const isAdminRoute = currentPath.startsWith('/admin');
    const tokenType = isAdminRoute ? 'admin' : 'member';
    const token = TokenManager.getStoredToken(tokenType);

    // Early return if no token or expired
    if (!token || TokenManager.isTokenExpired(token, tokenType)) {
      throw new Error('Token invalid or expired');
    }

    try {
      const response = await axiosInstance.get(
        isAdminRoute ? '/members/current-user-admin' : '/members/current-user',
        { [isAdminRoute ? 'useAdminToken' : 'useMemberToken']: true }
      );

      store.dispatch(
        isAdminRoute ? 
          setAdmin({ user: response.data, token }) :
          setMember({ user: response.data, token })
      );

      return { initialized: true };
    } catch (error) {
      TokenManager.clearToken(tokenType);
      throw error;
    }
  },

  async register(userData) {
    store.dispatch(clearError('member')); // Clear any existing errors before registration
    try {
      return await axiosInstance.post('/auth/register', userData);
    } catch (error) {
      const errorMessage = error.response?.data?.message ||
        error.message ||
        'Registration failed. Please try again.';

      store.dispatch(setError({
        type: 'member',
        message: errorMessage
      }));

      throw error;
    }
  },

  async adminLoginAsUser(username) {
    store.dispatch(setLoading(true));
    
    try {
      // Clear any existing member state and tokens
      store.dispatch(clearError('member'));
      store.dispatch(clearMember()); // Clear existing member state in Redux
      TokenManager.clearToken('member');
      logger.log('Admin attempting to login as member', { username });
  
      // Get admin token from current admin session
      const adminToken = TokenManager.getStoredToken('admin');
      if (!adminToken) {
        throw new Error('Admin authentication required');
      }
  
      const response = await axiosInstance.post('/auth/admin-user-login', 
        { username },
        { useAdminToken: true }
      );
  
      if (response.data.token && response.data.user) {
        console.log('Server response data:', response.data);
        console.log('User data being dispatched:', response.data.user);
        // Set up member session in current window
        TokenManager.setStoredToken('member', response.data.token);
        store.dispatch(setMember({ 
          user: response.data.user, 
          token: response.data.token 
        }));
  
        // Create a new tab with auto-login for member dashboard
        const loginWindow = window.open('', '_blank');
        if (loginWindow) {
          loginWindow.document.write(`
            <!DOCTYPE html>
            <html>
              <head>
                <title>Logging in as ${username}</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin: 0;
                    background-color: #f5f5f5;
                  }
                  .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #3498db;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    animation: spin 1s linear infinite;
                    margin-right: 10px;
                  }
                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                  .container {
                    text-align: center;
                    background: white;
                    padding: 2rem;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                  }
                </style>
              </head>
              <body>
                <div class="container">
                  <div class="loader"></div>
                  <p>Logging in as ${username}...</p>
                  <script>
                    try {
                      // Clear any existing storage
                      localStorage.clear();
                      
                      // Store the member token and user data using the same keys as regular login
                      localStorage.setItem('memberToken', '${response.data.token}');
                      localStorage.setItem('adminToken', '${adminToken}');
                      localStorage.setItem('userData', '${JSON.stringify(response.data.user).replace(/'/g, "\\'")}');
                      
                      // Redirect to member dashboard
                      window.location.href = '/member/dashboard';
                    } catch (error) {
                      document.body.innerHTML += '<p style="color: red;">Error during login process. Please try again.</p>';
                    }
                  </script>
                </div>
              </body>
            </html>
          `);
        }
        
        logger.log('Admin login as member successful', { 
          adminId: JSON.parse(atob(adminToken.split('.')[1])).id,
          targetUserId: response.data.user.id 
        });
      }
  
      return response.data;
    } catch (error) {
      logger.error('Admin login as member error', error);
      
      const errorMessage = error.response?.data?.message || 
        error.message ||
        (!error.response ? 'Server does not respond' : 'Error logging in as user');
  
      store.dispatch(setError({
        type: 'admin',
        message: errorMessage
      }));
      
      throw error;
    } finally {
      store.dispatch(setLoading(false));
    }
  },

  async forgotPassword(email) {
    try {
      const response = await axiosInstance.post('/auth/forgot-password', { email });
      return response.data;
    } catch (error) {
      logger.error('Forgot password API error:', error);
      throw error;
    }
  },

  async resetPassword(token, newPassword) {
    try {
      const response = await axiosInstance.post('/auth/reset-password', {
        token,
        newPassword
      });
      return response.data;
    } catch (error) {
      logger.error('Reset password API error:', error);
      throw error;
    }
  }
};

export default authService;