import { useState, useEffect } from 'react';
import memberService from '../../../api/memberService';
import payPlansService from '../../../api/payPlansService';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';
import payTypeService from '../../../api/payTypeService';

const ActivateMemberModal = ({ isOpen, onClose, selectedMember, onActivateMember }) => {
  const [registrationFee, setRegistrationFee] = useState(0);
  const [payTypes, setPayTypes] = useState([]);
  const [formData, setFormData] = useState({
    amount: 0,
    activationDate: new Date().toISOString().split('T')[0],
    payType: ''
  });
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();

  useEffect(() => {
    const fetchMemberData = async () => {
      if (isOpen && selectedMember) {
        setLoading(true);
        try {
          const payPlans = await payPlansService.getPublicPayPlans();
          const selectedPlan = payPlans.find(plan => plan.id === parseInt(selectedMember.planId));

          if (selectedPlan) {
            setRegistrationFee(selectedPlan.registrationFee);
            setFormData(prev => ({
              ...prev,
              amount: selectedPlan.registrationFee
            }));
          }
        } catch (error) {
          console.error('Error fetching member data:', error);
          showAlert('error', 'Failed to fetch latest member data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMemberData();
    fetchPayTypes();
  }, [isOpen, showAlert, selectedMember]);

  const fetchPayTypes = async () => {
    try {
      const response = await payTypeService.getPayTypes();
      setPayTypes(response.data.data);
      // Set default payTypeId if payment types exist
      if (response.data.data.length > 0) {
        setFormData(prev => ({
          ...prev,
          payType: response.data.data[0].id
        }));
      }
    } catch (error) {
      console.error('Error fetching payment types:', error);
      showAlert('error', 'Failed to load payment types');     
    }
      };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.payType) {
      showAlert('error', 'Please select a payment type');
      return;
    }

    openModal('Confirm Submission', 'Are you sure you want to sActivate Member?', async () => {
      setLoading(true);
      try {
        const activationData = {
          planId: selectedMember.planId,
          amount: formData.amount,
          activationDate: formData.activationDate,
          payType: formData.payType
        };

     await onActivateMember(activationData);
     onClose();
    } catch (error) {
      console.error('Activation error:', error);
      showAlert('error', error.message || 'Failed to activate member');
    } finally {
      setLoading(false);
    }
  });
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="px-6 py-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold text-gray-900">
            Activate Member
          </h3>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Registration Fee
              </label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Enter registration fee"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={loading}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Activation Date
              </label>
              <input
                type="date"
                name="activationDate"
                value={formData.activationDate}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={loading}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Payment Type
              </label>
              {payTypes.length > 0 ? (
                <select
                  name="payTypeId"
                  value={formData.payTypeId}
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                >
                  {payTypes.map(payType => (
                    <option key={payType.id} value={payType.id}>
                      {payType.name}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="text-sm text-gray-500 p-2 border rounded bg-gray-50">
                  No payment types available. Please contact administrator.
                </div>
              )}
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-blue-400"
              disabled={loading}
            >
              {loading ? 'Activating...' : 'Activate Member'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActivateMemberModal;