import React from "react";
import earningsService from "../../../api/earningsService";

const StatusBadge = ({ status }) => {
    const badgeClass = earningsService.getStatusBadgeClass(status);
    
    return (
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${badgeClass}`}>
        {status}
      </span>
    );
  };
  
  export default StatusBadge;