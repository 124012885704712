// src/hooks/useDocumentHead.js
import { useEffect } from 'react';
import { useSettings } from './useSettings';
import { useLogo } from './useLogo';

export function useDocumentHead() {
  const { settings } = useSettings();
  const { logoUrl } = useLogo(settings.siteLogo);

  useEffect(() => {
    // Update document title
    if (settings.siteName) {
      document.title = settings.siteName;
    }

    // Update favicon
    if (logoUrl) {
      // Remove any existing favicon
      const existingFavicon = document.querySelector("link[rel*='icon']");
      if (existingFavicon) {
        document.head.removeChild(existingFavicon);
      }

      // Create new favicon link
      const favicon = document.createElement('link');
      favicon.type = 'image/x-icon';
      favicon.rel = 'shortcut icon';
      favicon.href = logoUrl;
      document.head.appendChild(favicon);
    }

    // Cleanup function
    return () => {
      // Reset title and favicon when component unmounts
      document.title = 'Loading...';
      const favicon = document.querySelector("link[rel*='icon']");
      if (favicon) {
        document.head.removeChild(favicon);
      }
    };
  }, [settings.siteName, logoUrl]);
}