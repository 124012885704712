import React, { useState } from 'react';
import { Copy, Check, AlertTriangle, Bitcoin, Wallet } from 'lucide-react';

const WalletAddressCard = () => {
  const [copiedAddress, setCopiedAddress] = useState(null);

  const wallets = [
    {
      type: 'USDT (TRC20)',
      address: 'TP4pMJot3mbhvfqFERFojcZCLCjfCeKa8E',
      icon: Wallet,
      gradient: 'from-green-500 to-teal-600'
    },
    {
      type: 'Bitcoin (BTC)',
      address: '1Hpasec4vtiy6iy2teGn5Wmm4zvMYcDhu2',
      icon: Bitcoin,
      gradient: 'from-orange-500 to-yellow-600'
    }
  ];

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    setCopiedAddress(address);
    setTimeout(() => setCopiedAddress(null), 2000);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4 bg-gradient-to-r from-purple-500 to-purple-600">
        <h2 className="text-xl font-semibold text-white flex items-center gap-2">
          <Wallet size={24} />
          Payment Addresses
        </h2>
      </div>
      
      <div className="p-6">
        {/* Warning Message */}
        <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded flex items-start gap-2">
          <AlertTriangle className="text-yellow-500 mt-1 flex-shrink-0" size={20} />
          <p className="text-sm text-yellow-700">
            Please verify the address carefully before making any payments. We are not responsible for funds sent to incorrect addresses.
          </p>
        </div>

        {/* Wallet Addresses */}
        <div className="space-y-4">
          {wallets.map((wallet) => {
            const Icon = wallet.icon;
            return (
              <div 
                key={wallet.type}
                className="border rounded-lg p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-center gap-2 mb-2">
                  <Icon className={`text-${wallet.gradient.split('-')[1]}-500`} size={20} />
                  <span className="font-semibold text-gray-700">{wallet.type}</span>
                </div>
                
                <div className="flex items-center gap-2">
                  <code className="flex-1 bg-white p-2 rounded text-sm font-mono break-all border">
                    {wallet.address}
                  </code>
                  <button
                    onClick={() => handleCopy(wallet.address)}
                    className="p-2 hover:bg-gray-200 rounded transition-colors flex-shrink-0"
                    title="Copy address"
                  >
                    {copiedAddress === wallet.address ? (
                      <Check size={20} className="text-green-500" />
                    ) : (
                      <Copy size={20} className="text-gray-500" />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Additional Instructions */}
        <div className="mt-4 text-sm text-gray-600">
          <p className="font-semibold mb-1">Important:</p>
          <ul className="list-disc list-inside space-y-1 pl-2">
            <li>Only send USDT through the TRC20 network</li>
            <li>Minimum confirmation required: 2 blocks</li>
            <li>Include transaction hash when reporting payment</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WalletAddressCard;