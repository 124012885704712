import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { Send, Image, Paperclip, Download, Maximize, X } from 'lucide-react';
import { useAlert } from '../../../hooks/useAlert';
import ticketsService from '../../../api/ticketsServices';
import fileHandlingService from '../../../api/fileHandlingService';

const TICKET_STATUSES = [
  { value: 'OPEN', label: 'Open', color: 'yellow' },
  { value: 'IN_PROGRESS', label: 'In Progress', color: 'blue' },
  { value: 'CLOSED', label: 'Closed', color: 'gray' },
  { value: 'PAYMENT_APPROVED', label: 'Payment Approved', color: 'green' },
];

const TicketStatusBadge = ({ status }) => {
  const statusConfig = TICKET_STATUSES.find(s => s.value === status) || {
    label: status,
    color: 'gray'
  };

  const colorClasses = {
    yellow: 'bg-yellow-100 text-yellow-800',
    blue: 'bg-blue-100 text-blue-800',
    green: 'bg-green-100 text-green-800',
    gray: 'bg-gray-100 text-gray-800',
    orange: 'bg-orange-100 text-orange-800',
    purple: 'bg-purple-100 text-purple-800'
  };

  return (
    <span className={`px-2 py-1 text-sm font-medium rounded-full ${colorClasses[statusConfig.color]}`}>
      {statusConfig.label}
    </span>
  );
};

const TicketHeader = ({ ticket, isAdmin, onStatusChange }) => {
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const [newStatus, setNewStatus] = useState(ticket.status);
  const [updating, setUpdating] = useState(false);
  const { showAlert } = useAlert();

  const handleStatusUpdate = async () => {
    if (newStatus === ticket.status) {
      setIsChangingStatus(false);
      return;
    }

    setUpdating(true);
    try {
      const response = await ticketsService.updateTicketStatus(ticket.id, newStatus);
      if (response.success) {
        showAlert('success', 'Ticket status updated successfully');
        onStatusChange(response.data);
        setIsChangingStatus(false);
      }
    } catch (error) {
      showAlert('error', 'Failed to update ticket status');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="bg-gray-50 p-4 border-b">
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-semibold">
              Ticket #{ticket.id} - {ticket.subject}
            </h3>
            <div className="flex items-center space-x-2 mt-1">
              {isAdmin && !isChangingStatus ? (
                <button
                  onClick={() => setIsChangingStatus(true)}
                  className="flex items-center space-x-2 text-sm text-gray-600 hover:text-blue-600"
                >
                  <TicketStatusBadge status={ticket.status} />
                  <span className="text-xs">(click to change)</span>
                </button>
              ) : !isAdmin ? (
                <TicketStatusBadge status={ticket.status} />
              ) : null}
              
              {isAdmin && isChangingStatus && (
                <div className="flex items-center space-x-2">
                  <select
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                    className="border rounded px-2 py-1 text-sm"
                    disabled={updating}
                  >
                    {TICKET_STATUSES
                      .filter(status => 
                        (ticket.type === 'PAYMENT' && status.value !== 'CLOSED') ||
                        (ticket.type !== 'PAYMENT' && status.value !== 'PAYMENT_APPROVED')
                      )
                      .map(status => (
                        <option key={status.value} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                  </select>
                  <button
                    onClick={handleStatusUpdate}
                    disabled={updating}
                    className="px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600 
                             disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {updating ? 'Updating...' : 'Update'}
                  </button>
                  <button
                    onClick={() => {
                      setIsChangingStatus(false);
                      setNewStatus(ticket.status);
                    }}
                    disabled={updating}
                    className="px-3 py-1 text-sm bg-gray-500 text-white rounded hover:bg-gray-600
                             disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Cancel
                  </button>
                </div>
              )}
              <span className="text-sm text-gray-600">
                • {isAdmin ? `Member: ${ticket.member.username}` : 'Admin Support'}
              </span>
            </div>
          </div>
          {ticket.type === 'PAYMENT' && (
            <div className="text-right text-sm">
              <div className="font-medium">Payment Details:</div>
              <div>Amount: ${ticket.transactionAmount}</div>
              <div>Method: {ticket.payType?.name}</div>
              {ticket.transactionHash && (
                <div className="truncate max-w-xs">
                  Hash: {ticket.transactionHash}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MessageBubble = memo(({ message, isAdmin, onImageLoad }) => {
  const messageIsFromCurrentUser = isAdmin ? message.isAdmin : !message.isAdmin;
  const bubbleClass = messageIsFromCurrentUser
    ? 'bg-blue-500 text-white ml-auto'
    : 'bg-gray-100 text-gray-800';
  
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isImageFile = (filename) => {
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(filename);
  };

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, []);

  useEffect(() => {
    if (message.attachment && isImageFile(message.attachment)) {
      setLoading(true);
      setError(null);
      
      const loadImage = async () => {
        try {
          const blob = await fileHandlingService.getFileData(message.attachment, 'ticket');
          const mimeType = fileHandlingService.getMimeType(message.attachment);
          const blobWithType = new Blob([blob], { type: mimeType });
          const url = URL.createObjectURL(blobWithType);
          
          setPreviewUrl(url);
          setLoading(false);
          if (onImageLoad) onImageLoad();
        } catch (err) {
          console.error('Failed to load image:', err);
          setError('Failed to load image preview');
          setLoading(false);
        }
      };

      loadImage();
    }
  }, [message.attachment]);

  return (
    <div className={`max-w-[70%] ${messageIsFromCurrentUser ? 'ml-auto' : 'mr-auto'} mb-4`}>
      <div className={`rounded-lg p-3 ${bubbleClass}`}>
        <div className="flex justify-between items-start mb-1">
          <span className="font-semibold text-sm">
            {message.isAdmin ? 'Admin' : message.sender.username}
          </span>
          <span className="text-xs opacity-75 ml-2">
            {new Date(message.sentAt).toLocaleString()}
          </span>
        </div>
        
        {message.content && (
          <p className="whitespace-pre-wrap break-words">{message.content}</p>
        )}

        {message.attachment && (
          <div className="mt-2">
            {isImageFile(message.attachment) ? (
              <div className="relative group">
                {loading ? (
                  <div className="flex items-center justify-center h-32 bg-gray-100 rounded">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                  </div>
                ) : error ? (
                  <div className="flex items-center justify-center h-32 bg-gray-100 rounded text-red-500">
                    {error}
                  </div>
                ) : (
                  <div className="relative w-48 h-48 overflow-hidden rounded cursor-pointer group">
                    <img
                      src={previewUrl}
                      alt="Attachment"
                      className="absolute inset-0 w-full h-full object-cover hover:opacity-90 transition-opacity"
                      onClick={() => ticketsService.openFile(message.attachment, 'ticket')}
                      onLoad={onImageLoad}
                    />
                    {/* Image overlay and controls */}
                    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all duration-200">
                      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            fileHandlingService.openFile(message.attachment, 'ticket');
                          }}
                          className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                          title="Open in new tab"
                        >
                          <Maximize size={16} />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            fileHandlingService.downloadFile(message.attachment, 'ticket');
                          }}
                          className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                          title="Download"
                        >
                          <Download size={16} />
                        </button>
                      </div>
                      <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-50 text-white text-xs truncate opacity-0 group-hover:opacity-100 transition-opacity">
                        {message.attachment.split('/').pop()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center p-3 bg-black bg-opacity-5 rounded">
                <Paperclip size={16} className="mr-2" />
                <div className="flex-1 min-w-0">
                  <div className="text-sm font-medium truncate">
                    {message.attachment.split('/').pop()}
                  </div>
                </div>
                <button
                  onClick={() => ticketsService.downloadFile(message.attachment)}
                  className="ml-2 p-2 rounded hover:bg-black hover:bg-opacity-10"
                  title="Download"
                >
                  <Download size={16} />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

const TicketChat = ({ ticket: initialTicket, onClose, isAdmin = false }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const { showAlert } = useAlert();
  const [ticket, setTicket] = useState(initialTicket);

  const handleStatusChange = (updatedTicket) => {
    setTicket(updatedTicket);
  };


  const scrollToBottom = useCallback((smooth = true) => {
    if (messagesContainerRef.current) {
      const scrollOptions = smooth ? { behavior: 'smooth' } : {};
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  const handleImageLoad = useCallback(() => {
    scrollToBottom(false);
  }, [scrollToBottom]);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const data = await ticketsService.getTicket(ticket.id);
      if (data.success) {
        setMessages(data.data.messages);
        // Scroll without smooth behavior on initial load
        setTimeout(() => scrollToBottom(false), 100);
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
      showAlert('error', 'Failed to load messages');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  

    fetchMessages();
  }, [ticket.id]);

  const handleSend = async () => {
    if (!newMessage.trim() && !attachment) return;

    try {
      const messageData = {
        content: newMessage.trim(),
        attachment: attachment
      };

      const response = await (isAdmin 
        ? ticketsService.addMessageAdmin(ticket.id, messageData)
        : ticketsService.addMessageMember(ticket.id, messageData)
      );
      
      if (response) {
        setNewMessage('');
        setAttachment(null);
        setAttachmentPreview(null);
        
        // Update messages and scroll
        fetchMessages();
      }
    } catch (error) {
      showAlert('error', 'Failed to send message');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        showAlert('error', 'File size must be less than 5MB');
        return;
      }

      setAttachment(file);
      
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachmentPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setAttachmentPreview(null);
      }
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const clearAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="flex flex-col h-full bg-white">
      {/* Header */}
      <TicketHeader 
        ticket={ticket}
        isAdmin={isAdmin}
        onStatusChange={handleStatusChange}
      />

      {/* Messages */}
      <div ref={messagesContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <>
            {messages.map((message) => (
              <MessageBubble 
                key={message.id} 
                message={message} 
                isAdmin={isAdmin}
                onImageLoad={handleImageLoad}
              />
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      {/* Input Area */}
      <div className="border-t p-4">
        <div className="flex items-end space-x-4">
          <div className="flex-1">
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              className="w-full border rounded-lg p-3 min-h-[100px] resize-none"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
            />
            
            {(attachment || attachmentPreview) && (
              <div className="mt-2 p-2 bg-gray-50 rounded-lg">
                {attachmentPreview ? (
                  <div className="relative inline-block">
                    <img
                      src={attachmentPreview}
                      alt="Preview"
                      className="max-h-32 rounded"
                    />
                    <button
                      onClick={clearAttachment}
                      className="absolute -top-2 -right-2 p-1 bg-red-500 rounded-full text-white"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center justify-between p-2">
                    <div className="flex items-center space-x-2">
                      <Paperclip size={16} />
                      <span className="text-sm truncate">{attachment.name}</span>
                      <span className="text-xs text-gray-500">
                        ({formatFileSize(attachment.size)})
                      </span>
                    </div>
                    <button
                      onClick={clearAttachment}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X size={16} />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          
          <div className="flex space-x-2">
            <button
              onClick={() => fileInputRef.current?.click()}
              disabled={ticket.status === "CLOSED" || ticket.status === "PAYMENT_APPROVED"}
              className="p-3 rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-600 disabled:opacity-50"
            >
              <Image size={20} />
            </button>
            <button
              onClick={handleSend}
              disabled={!newMessage.trim() && !attachment || ticket.status === "CLOSED" || ticket.status === "PAYMENT_APPROVED"}
              className="p-3 rounded-lg bg-blue-500 hover:bg-blue-600 text-white disabled:opacity-50"
            >
              <Send size={20} />
            </button>
          </div>
          
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileSelect}
            accept="image/*,.pdf,.doc,.docx"
          />
        </div>
      </div>
    </div>
  );
};

export default TicketChat;