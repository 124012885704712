// pages/member/tickets/index.js
import React, { useState } from 'react';
import TicketManagement from '../common/tickets/TicketsManagement';
import CreateTicketModal from '../common/tickets/CreateTicket';
import { Plus } from 'lucide-react';

const MemberTickets = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleTicketCreated = () => {
    // Trigger a refresh of the tickets list
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* Create Ticket Button */}
      <div className="flex justify-end px-4">
        <button
          onClick={() => setShowCreateModal(true)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                     transition-colors duration-200 flex items-center space-x-2"
        >
          <Plus size={20} />
          <span>Create Ticket</span>
        </button>
      </div>

      {/* Tickets Management Component */}
      <TicketManagement 
        isAdmin={false}
        refreshTrigger={refreshTrigger}
      />

      {/* Create Ticket Modal */}
      <CreateTicketModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onTicketCreated={handleTicketCreated}
      />
    </div>
  );
};

export default MemberTickets;