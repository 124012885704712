// services/earningsService.js
import { axiosInstance } from './config/axiosConfig';
import { handleError } from '../utils/errorHandler';

const earningsService = {
    // Fetch all earnings with filters and pagination
    getAllEarnings: async (filters = {}) => {
        try {
            const { 
                status, 
                memberUsername, 
                dateFrom, 
                dateTo,
                commissionId,
                memberId,
                page = 1, 
                limit = 10 
            } = filters;

            const queryParams = new URLSearchParams();
            if (status) queryParams.append('status', status);
            if (memberUsername) queryParams.append('memberUsername', memberUsername)
            if (commissionId) queryParams.append('commissionId', commissionId);
            if (dateFrom) queryParams.append('dateFrom', dateFrom);
            if (dateTo) queryParams.append('dateTo', dateTo);
            if (memberId) queryParams.append('memberId', memberId);
            queryParams.append('page', page);
            queryParams.append('limit', limit);

            const response = await axiosInstance.get(
                `/earnings?${queryParams.toString()}`,
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'fetching earnings');
        }
    },

    // Get single earning details
    getEarning: async (id) => {
        try {
            const response = await axiosInstance.get(
                `/earnings/${id}`,
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'fetching earning details');
        }
    },

    getMemberEarningsStats: async (memberId, dateRange = {}) => {
        try {
          let url = `/earnings/stats/${memberId}`;
          
          // Add date range parameters if provided
          if (dateRange.from || dateRange.to) {
            const params = new URLSearchParams();
            if (dateRange.from) params.append('dateFrom', dateRange.from);
            if (dateRange.to) params.append('dateTo', dateRange.to);
            url += `?${params.toString()}`;
          }
    
          const response = await axiosInstance.get(url);
          return response.data;
        } catch (error) {
          console.error('Error fetching earnings stats:', error);
          throw error;
        }
      },

    // Create manual earning
    createManualEarning: async (earningData) => {
        try {
            const response = await axiosInstance.post(
                '/earnings/manual',
                earningData,
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'creating manual earning');
        }
    },

    // Process deduction
    processDeduction: async (deductionData) => {
        try {
            const response = await axiosInstance.post(
                '/earnings/deduct',
                deductionData,
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'processing deduction');
        }
    },

    // Update earning status
    updateEarningStatus: async (earningId, status) => {
        try {
            const response = await axiosInstance.patch(
                `/earnings/${earningId}/status`,
                { status },
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'updating earning status');
        }
    },
    
    // Helper function to validate payment data
    validatePaymentDetails: (details) => {
      const requiredFields = ['payTypeId', 'referenceNumber'];
      const missingFields = requiredFields.filter(field => !details[field]);
      
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }
      
      // Add any additional validation rules here
      if (details.screenshot && !(details.screenshot instanceof File)) {
        throw new Error('Screenshot must be a file');
      }
      
      return true;
    },

    // Batch update earnings status
    batchUpdateStatus: async (earningIds, status) => {
        try {
            const response = await axiosInstance.patch(
                '/earnings/batch-status',
                { 
                    earningIds,
                    status 
                },
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'batch updating earnings status');
        }
    },
    processWithdrawal: async (withdrawalData) => {
        try {
          const response = await axiosInstance.post('/withdrawals/withdraw', withdrawalData);
          return response.data;
        } catch (error) {
          throw error;
        }
      },
    
    processWithdrawalByAmount: async (withdrawalData) => {
      try {
        const response = await axiosInstance.post('/withdrawals/withdraw-amount', withdrawalData);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getAllWithdrawals: async (params) => {
      try {
        const { 
          page = 1, 
          limit = 10, 
          username = '',
          status = '', 
          dateFrom = '', 
          dateTo = '' 
        } = params;
  
        let url = `/withdrawals/all?page=${page}&limit=${limit}`;

        if (username) url += `&memberUsername=${username}`;
        
        if (status) url += `&status=${status}`;
        if (dateFrom) url += `&dateFrom=${dateFrom}`;
        if (dateTo) url += `&dateTo=${dateTo}`;
  
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getWithdrawalById: async (withdrawalId) => {
      try {
        const response = await axiosInstance.get(`/withdrawals/${withdrawalId}`);
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

     getPaymentById: async (paymentId) => {
      try {
        const response = await axiosInstance.get(
          `/withdrawals-payments/payments/${paymentId}`,
          { useAdminToken: true }
        );
        return response.data.data;
      } catch (error) {
        throw new Error(error.response?.data?.message || 'Failed to fetch payment details');
      }
    },
  

    // Get member earnings summary
    getMemberEarningsSummary: async (memberId) => {
        try {
            const response = await axiosInstance.get(
                `/earnings/member/${memberId}/summary`,
                { useAdminToken: true }
            );
            return response.data;
        } catch (error) {
            handleError(error, 'fetching member earnings summary');
        }
    },

  // Get status color class
    getStatusColorClass: (status) => {
        const classes = {
            SCHEDULED: 'text-yellow-600',
            AVAILABLE: 'text-green-600',
            WITHDRAWN: 'text-blue-600',
            PAID: 'text-purple-600',
            ON_HOLD: 'text-orange-600',
            DEDUCTED: 'text-gray-600',
            CANCELLED: 'text-red-600'
        };
        return classes[status] || 'text-gray-600';
    },

    // Get status badge class
    getStatusBadgeClass: (status) => {
        const classes = {
            SCHEDULED: 'bg-yellow-100 text-yellow-800',
            AVAILABLE: 'bg-green-100 text-green-800',
            WITHDRAWN: 'bg-blue-100 text-blue-800',
            PAID: 'bg-purple-100 text-purple-800',
            ON_HOLD: 'bg-orange-100 text-orange-800',
            DEDUCTED: 'bg-gray-100 text-gray-800',
            CANCELLED: 'bg-red-100 text-red-800'
        };
        return classes[status] || 'bg-gray-100 text-gray-800';
    },

    // Validate earning amount
    validateEarningAmount: (amount) => {
        const parsedAmount = parseFloat(amount);
        return !isNaN(parsedAmount) && parsedAmount > 0;
    },

    // Check if earning is editable
    isEarningEditable: (status) => {
        const editableStatuses = ['SCHEDULED', 'AVAILABLE', 'ON_HOLD'];
        return editableStatuses.includes(status);
    },

    // Calculate total available balance
    calculateAvailableBalance: (earnings) => {
        return earnings
            .filter(earning => earning.status === 'AVAILABLE')
            .reduce((sum, earning) => sum + earning.amount, 0);
    },

    // Calculate total on hold balance
    calculateOnHoldBalance: (earnings) => {
        return earnings
            .filter(earning => earning.status === 'ON_HOLD')
            .reduce((sum, earning) => sum + earning.amount, 0);
    },
    searchMembers: async (query) => {
        try {
          if (!query || query.length < 2) {
            throw new Error('Search query must be at least 2 characters long');
          }
      
          const response = await axiosInstance.get(
            `/members/search?q=${encodeURIComponent(query)}`,
            { useAdminToken: true }
          );
      
          // Transform the response data if needed
          const members = response.data.members.map(member => ({
            ...member,
            availableBalance: parseFloat(member.availableBalance || 0),
            formattedBalance: member.availableBalance ? 
              new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(member.availableBalance) : '$0.00',
            formattedDate: new Date(member.createdAt).toLocaleDateString(),
            statusColor: member.planStatus === 'ACTIVE' ? 'green' : 
                        member.planStatus === 'INACTIVE' ? 'gray' : 
                        member.planStatus === 'EXPIRED' ? 'red' : 'yellow'
          }));
      
          return {
            members,
            metadata: response.data.metadata
          };
        } catch (error) {
          handleError(error, 'searching members');
          throw error;
        }
      },

    formatAmount: (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount);
    },
  
    formatDate: (date) => {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },

    processPayment: async (paymentData) => {
      try {
        const response = await axiosInstance.post(
          '/withdrawals-payments/process-payment',
          paymentData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            useAdminToken: true
          }
        );
        return response.data;
      } catch (error) {
        handleError(error, 'processing withdrawal payment');
      }
    },
  
    getPayTypes: async () => {
      try {
        const response = await axiosInstance.get('/pay-types');
        return response.data.data;
      } catch (error) {
        handleError(error, 'fetching pay types');
      }
    }
};

export default earningsService;