import { useState } from 'react';
import memberService from '../../../api/memberService';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';

const DeactivateMemberModal = ({ isOpen, onClose, selectedMember, onDeactivateMember }) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();
  const currentDate = new Date().toISOString().split('T')[0];

  const [formData, setFormData] = useState({
    deactivationDate: currentDate,
    reason: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.reason.trim()) {
      showAlert('error', 'Please provide a reason for deactivation');
      return;
    }

    openModal(
      'Confirm Deactivation', 
      'Are you sure you want to deactivate this member? This action will cancel all pending earnings.',
      async () => {
        setLoading(true);
        try {
          await onDeactivateMember({
            effectiveDate: formData.deactivationDate,
            reason: formData.reason
          });
          onClose();
          
       
        } catch (error) {
          console.error('Deactivation error:', error);
          showAlert('error', error.message || 'Failed to deactivate member');
        } finally {
          setLoading(false);
        }
      }
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="px-6 py-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold text-gray-900">
            Deactivate Member
          </h3>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Deactivation Date
              </label>
              <input
                type="date"
                name="deactivationDate"
                value={formData.deactivationDate}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={loading}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Reason
              </label>
              <textarea
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                placeholder="Please provide a reason for deactivation"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                disabled={loading}
                required
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 disabled:bg-red-400"
              disabled={loading}
            >
              {loading ? 'Deactivating...' : 'Deactivate Member'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeactivateMemberModal;