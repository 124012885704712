import React, { useState, useEffect } from 'react';
import memberService from '../../api/memberService';
import trees from '../../api/treesService';
import { useAlert } from '../../hooks/useAlert';
import { useSelector } from 'react-redux';
import MemberTree from '../admin/trees/MemberTree';

function MemberTrees() {
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { showAlert } = useAlert();



  const { member } = useSelector((state) => state.auth);


  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    setLoading(true);
    try {
      const depth = member.currentUser?.plan?.depth || 10;
      const response = await trees.getMemberTree(member.currentUser.id, depth);
      setTreeData(response);
    } catch (error) {
      setError('Error fetching data. Please try again.');
      showAlert({ type: 'error', message: 'Error fetching data. Please try again.' });
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">My Tree</h2>
      <p className="text-gray-600 mb-4">
        This is a visual representation of your referral network. Each node represents a member you've referred or who has been referred by someone in your network.
      </p>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {loading && <p className="text-gray-600 mb-4">Loading...</p>}
      {treeData && <MemberTree treeData={treeData} maxDepth={4} />}
    

    </div>
  );
}

export default MemberTrees;