// sections/WithdrawalsSection.js
import React, { useState, useEffect } from 'react';
import { Search, ArrowUp, ArrowDown, Clock, DollarSign, CheckCircle, XCircle } from 'lucide-react';
import earningsService from '../../../api/earningsService';
import { useAlert } from '../../../hooks/useAlert';
import Pagination from '../../common/Pagination';
import { useNavigate } from 'react-router-dom';

const WithdrawalsSection = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [sortDirection, setSortDirection] = useState('desc');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    username: '',
    status: '',
    dateFrom: '',
    dateTo: '',
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const fetchWithdrawals = async () => {
    setIsLoading(true);
    try {
      const data = await earningsService.getAllWithdrawals({
        ...filters,
        page: pagination.pageNumber,
        limit: pagination.pageSize,
      });
      setWithdrawals(data.data);
      setPagination(data.metadata);
    } catch (err) {
      setError(err.message || 'An error occurred while fetching withdrawals');
      showAlert('error', 'Failed to fetch withdrawals');
    } finally {
      setIsLoading(false);
    }
  };

  const handleProcessWithdrawal = async (withdrawalId) => {
    try {
      //get string withdrawalId
      //call api to process withdrawal
      // navigate to manage-earnings with withdrawalId
      navigate(`/admin/manage-earnings?withdrawalId=${withdrawalId}`);
     
    } catch (err) {
      setError(err.message || 'An error occurred while processing withdrawal');
      showAlert('error', 'Failed to process withdrawal');
    }
  };

  useEffect(() => {
    fetchWithdrawals();
  }, [pagination.pageNumber, pagination.pageSize]);

  const handleDateSort = () => {
    const sortedWithdrawals = [...withdrawals].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setWithdrawals(sortedWithdrawals);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
    fetchWithdrawals();
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination(prev => ({
      ...prev,
      pageSize: newPageSize,
      pageNumber: 1
    }));
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusBadgeClass = (status) => {
    const classes = {
      PENDING: 'bg-yellow-100 text-yellow-800',
      PROCESSING: 'bg-blue-100 text-blue-800',
      COMPLETED: 'bg-green-100 text-green-800',
      REJECTED: 'bg-red-100 text-red-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4 bg-red-100 rounded-lg">{error}</div>;
  }

  return (
    <>
      {/* Withdrawal Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-6">
        <div className="bg-yellow-50 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <Clock className="h-8 w-8 text-yellow-500 mr-3" />
            <div>
              <h3 className="text-yellow-700 text-lg font-semibold">Pending</h3>
              <p className="text-2xl font-bold text-yellow-800">
                {formatAmount(
                  withdrawals
                    .filter(w => w.status === 'PENDING')
                    .reduce((sum, w) => sum + w.amount, 0)
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <DollarSign className="h-8 w-8 text-blue-500 mr-3" />
            <div>
              <h3 className="text-blue-700 text-lg font-semibold">Processing</h3>
              <p className="text-2xl font-bold text-blue-800">
                {formatAmount(
                  withdrawals
                    .filter(w => w.status === 'PROCESSING')
                    .reduce((sum, w) => sum + w.amount, 0)
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-green-50 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <CheckCircle className="h-8 w-8 text-green-500 mr-3" />
            <div>
              <h3 className="text-green-700 text-lg font-semibold">Completed</h3>
              <p className="text-2xl font-bold text-green-800">
                {formatAmount(
                  withdrawals
                    .filter(w => w.status === 'COMPLETED')
                    .reduce((sum, w) => sum + w.amount, 0)
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-red-50 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <XCircle className="h-8 w-8 text-red-500 mr-3" />
            <div>
              <h3 className="text-red-700 text-lg font-semibold">Rejected</h3>
              <p className="text-2xl font-bold text-red-800">
                {formatAmount(
                  withdrawals
                    .filter(w => w.status === 'REJECTED')
                    .reduce((sum, w) => sum + w.amount, 0)
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Filters */}
      <form onSubmit={handleSearch} className="p-6 bg-gray-50 border-b border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
          <input
              type="text"
              name="username"
              value={filters.username}
              onChange={handleFilterChange}
              placeholder="Username"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
          
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Statuses</option>
              <option value="PENDING">Pending</option>
              <option value="PROCESSING">Processing</option>
              <option value="COMPLETED">Completed</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
          <div>
            <input
              type="date"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="From Date"
            />
          </div>
          <div>
            <input
              type="date"
              name="dateTo"
              value={filters.dateTo}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="To Date"
            />
          </div>
         
        </div>
        <div className="flex items-center justify-end mt-4" >
            <button 
              type="submit" 
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
            >
              <Search size={20} className="mr-2" />
              Search
            </button>
          </div>
      </form>

      <Pagination 
        pagination={pagination} 
        onPageChange={handlePageChange} 
        onPageSizeChange={handlePageSizeChange} 
      />

      {/* Withdrawals Table */}
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Member
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-200"
                onClick={handleDateSort}
              >
                <div className="flex items-center">
                  Request Date
                  {sortDirection === 'asc' 
                    ? <ArrowUp className="ml-1 h-4 w-4" />
                    : <ArrowDown className="ml-1 h-4 w-4" />
                  }
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Processed Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {withdrawals.map((withdrawal) => (
              <tr key={withdrawal.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {withdrawal.member.username}
                      </div>
                      <div className="text-sm text-gray-500">
                        {withdrawal.member.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatDate(withdrawal.date)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {formatAmount(withdrawal.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(withdrawal.status)}`}>
                    {withdrawal.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {withdrawal.processedDate 
                    ? formatDate(withdrawal.processedDate)
                    : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                
                    <div className="flex space-x-2">
                      <button 
                        onClick={() => handleProcessWithdrawal(withdrawal.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        View/Process Earnings
                      </button>
                      
                    </div>
                  
                 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination 
        pagination={pagination} 
        onPageChange={handlePageChange} 
        onPageSizeChange={handlePageSizeChange} 
      />
    </>
  );
};

export default WithdrawalsSection;