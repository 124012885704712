import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';


const paymentsService = {
  // Get paginated list of payments with filters
  getAllPayments: async (filters = {}) => {
    try {
      const { 
        page = 1, 
        limit = 10,
        memberUsername,
        payTypeId,
        dateFrom,
        dateTo 
      } = filters;

      const queryParams = new URLSearchParams();
      queryParams.append('page', page.toString());
      queryParams.append('limit', limit.toString());
      
      
      if (memberUsername) queryParams.append('memberUsername', memberUsername);
      if (payTypeId) queryParams.append('payTypeId', payTypeId);
      if (dateFrom) queryParams.append('dateFrom', dateFrom);
      if (dateTo) queryParams.append('dateTo', dateTo);

      const response = await axiosInstance.get(
        `/withdrawals-payments/list?${queryParams.toString()}`,
        { useAdminToken: true }
      );
      return response.data.data;
    } catch (error) {
      handleError(error, 'fetching payments');
    }
  },

  // Get detailed payment information
  getPaymentDetails: async (paymentId) => {
    try {
      const response = await axiosInstance.get(
        `/withdrawals-payments/details/${paymentId}`,
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'fetching payment details');
    }
  },

  // Get payment summary statistics
  getPaymentsSummary: async (dateRange = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (dateRange.from) queryParams.append('dateFrom', dateRange.from);
      if (dateRange.to) queryParams.append('dateTo', dateRange.to);

      const response = await axiosInstance.get(
        `/withdrawals-payments/summary?${queryParams.toString()}`,
        { useAdminToken: true }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'fetching payments summary');
    }
  },

  // Process payment by selected earnings
  processPaymentByEarnings: async (formData) => {
    try {
      const response = await axiosInstance.post(
        '/withdrawals-payments/process-payment-by-earnings',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          useAdminToken: true
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'processing payment by earnings');
    }
  },

  // Process payment by amount
  processPaymentByAmount: async (formData) => {
    try {
      const response = await axiosInstance.post(
        '/withdrawals-payments/process-payment-by-amount',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          useAdminToken: true
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'processing payment by amount');
    }
  },

  // Helper function to format currency
  formatAmount: (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  },

  // Helper function to format dates
  formatDate: (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  },

  // Helper function for payment status badges
  getPaymentStatusClass: (status) => {
    const classes = {
      PENDING: 'bg-yellow-100 text-yellow-800',
      COMPLETED: 'bg-green-100 text-green-800',
      CANCELLED: 'bg-red-100 text-red-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  },

  // Validate payment amount
  validatePaymentAmount: (amount) => {
    const parsedAmount = parseFloat(amount);
    return !isNaN(parsedAmount) && parsedAmount > 0;
  },

  // Helper to check if file is a valid image
  isValidImageFile: (file) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    return file && validTypes.includes(file.type);
  },

  // Helper to check file size (5MB limit)
  isValidFileSize: (file) => {
    const maxSize = 5 * 1024 * 1024; // 5MB
    return file && file.size <= maxSize;
  },

  // Helper to build screenshot URL
  getScreenshotUrl: (filename) => {
    if (!filename) return null;
    return `/api/withdrawals-payments/files/${filename}`;
  },

  // Helper to download screenshot
  downloadScreenshot: async (filename, originalName = 'screenshot') => {
    try {
      const response = await axiosInstance.get(
        `/withdrawals-payments/files/${filename}`,
        {
          responseType: 'blob'
        }
      );
      
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', originalName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleError(error, 'downloading screenshot');
    }
  },

  updatePayment: async (paymentId, formData) => {
    try {
      const response = await axiosInstance.put(
        `/withdrawals-payments/update/${paymentId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          useAdminToken: true
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, 'updating payment');
    }
  },

  // Helper to prepare form data for payment submission
  preparePaymentFormData: (paymentData, screenshot) => {
    const formData = new FormData();
    
    // Add all payment data
    Object.keys(paymentData).forEach(key => {
      if (paymentData[key] !== null && paymentData[key] !== undefined) {
        formData.append(key, paymentData[key]);
      }
    });

    // Add screenshot if present
    if (screenshot) {
      formData.append('screenshot', screenshot);
    }

    return formData;
  },
  getPayTypes: async () => {
    try {
      const response = await axiosInstance.get('/pay-types');
      return response.data.data;
    } catch (error) {
      handleError(error, 'fetching pay types');
    }
  }
};

export default paymentsService;