import React, { useState } from 'react';
import MemberEarnings from './MemberEarnings';
import MemberWithdrawals from './MemberWithdrawals';
import MemberPayments from './MemberPayments.js';
import { DollarSign, ArrowUpCircle, Wallet } from 'lucide-react';

const MemberTransactions = () => {
  const [activeTab, setActiveTab] = useState('earnings');

  const tabs = [
    {
      id: 'earnings',
      label: 'Earnings',
      icon: DollarSign,
      component: MemberEarnings
    },
    {
      id: 'withdrawals',
      label: 'Withdrawals',
      icon: ArrowUpCircle,
      component: MemberWithdrawals
    },
    {
      id: 'payments',
      label: 'Payments',
      icon: Wallet,
      component: MemberPayments
    }
  ];

  const renderTabs = () => (
    <div className="border-b border-gray-200">
      <nav className="flex space-x-8 px-6" aria-label="Tabs">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`py-4 px-1 border-b-2 font-medium text-sm flex items-center gap-2
              ${activeTab === tab.id
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <tab.icon size={16} />
            {tab.label}
          </button>
        ))}
      </nav>
    </div>
  );

  const ActiveComponent = tabs.find(tab => tab.id === activeTab)?.component || MemberEarnings;

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-indigo-600">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="text-white">
            <h2 className="text-2xl font-bold">My Transactions</h2>
            <p className="text-sm mt-1 opacity-90">
              View and manage your earnings and withdrawals
            </p>
          </div>
        </div>
      </div>

      {renderTabs()}
      <ActiveComponent />
    </div>
  );
};

export default MemberTransactions;