// services/reportPaymentsService.js
import {axiosInstance} from './config/axiosConfig';
import {handleError} from '../utils/errorHandler';
import { updateMemberPlanStatus, setError } from '../store/authSlice';

import store from '../store';
import { parse } from 'postcss';



const reportPaymentsService = {
  async reportPayment({ amount, txHash, paymentType, message, screenshot, payTypeId, userId }) {
    try {
      const formData = new FormData();
      formData.append('amount', amount);
      formData.append('txHash', txHash);
      formData.append('payTypeId', payTypeId); 
      formData.append('message', message);
      formData.append('paymentType', paymentType);
      formData.append('userId', userId);
 
      
      if (screenshot) {
        formData.append('screenshot', screenshot);
      }
      

      const response = await axiosInstance.post('/payment-report/report', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        // Update the member's plan status in Redux store
        store.dispatch(updateMemberPlanStatus('PAYMENT_REPORTED'));
      }


      return response.data;
    } catch (error) {
      handleError(error, 'reporting payment');
    }
  },

  async getPaymentStatus(ticketId) {
    try {
      const response = await axiosInstance.get(`/payment-report/status/${ticketId}`);
      return response.data;
    } catch (error) {
      handleError(error, 'fetching payment status');
    }
  }
};

export default reportPaymentsService;