import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Home, DollarSign, GitBranch, TicketIcon, Settings, FileText, LogOut, ChevronLeft, ChevronRight } from 'lucide-react';
import { useSettings } from '../../hooks/useSettings';
import { useLogo } from '../../hooks/useLogo';

function AdminSidebar({ isSidebarOpen, toggleSidebar, handleLogout }) {
  const { settings } = useSettings();
  const { logoUrl, loading: logoLoading } = useLogo(settings.siteLogo);

  const sidebarItems = [
    { to: "dashboard", icon: Home, text: "Dashboard" },
    { to: "manage-members", icon: Users, text: "Manage Members" },
    { to: "transactions", icon: DollarSign, text: "Transactions" },
    { to: "manage-earnings", icon: DollarSign, text: "Manage Earnings" },
    { to: "trees", icon: GitBranch, text: "Trees" },
    { to: "tickets", icon: TicketIcon, text: "Tickets" },
    { to: "general-settings", icon: Settings, text: "General Settings" },
    { to: "payplans", icon: FileText, text: "Payplans" },
  ];

  return (
    <div 
      className={`bg-blue-600 text-white ${
        isSidebarOpen ? 'w-64' : 'w-20'
      } flex flex-col justify-between transition-all duration-300 ease-in-out`}
    >
      <div>
        {/* Logo and Site Name Section */}
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className={`flex items-center ${isSidebarOpen ? 'space-x-4' : 'justify-center w-full'}`}>
              {/* Logo */}
              <div className={`flex-shrink-0 ${isSidebarOpen ? 'h-12 w-12' : 'h-14 w-14'} relative`}>
                {logoLoading ? (
                  <div className="animate-spin rounded-full h-full w-full border-b-2 border-white"></div>
                ) : logoUrl ? (
                  <img 
                    src={logoUrl}
                    alt={settings.siteName || 'Site Logo'} 
                    className="h-full w-full object-contain rounded-full bg-white p-1"
                  />
                ) : null}
              </div>
              
              {/* Site Name - Only show when sidebar is open */}
              {isSidebarOpen && (
                <div className="flex-1 min-w-0">
                  <h2 className="text-xl font-semibold truncate">
                    {settings.siteName || 'Admin Panel'}
                  </h2>
                </div>
              )}
            </div>
            
            {/* Toggle Button with Tooltip */}
            <button 
              onClick={toggleSidebar} 
              className={`group relative p-2 rounded-full hover:bg-blue-700 focus:outline-none flex-shrink-0 ${
                !isSidebarOpen ? 'mt-4 animate-pulse' : ''
              }`}
            >
              {isSidebarOpen ? (
                <ChevronLeft size={24} />
              ) : (
                <>
                  <ChevronRight size={24} />
                  <div className="absolute left-full ml-2 px-2 py-1 bg-black bg-opacity-75 text-white text-sm rounded-md whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Click to expand
                  </div>
                </>
              )}
            </button>
          </div>
        </div>

        {/* Navigation Items */}
        <nav className="mt-8">
          {sidebarItems.map((item, index) => (
            <Link 
              key={index}
              to={item.to} 
              className={`flex items-center py-3 px-4 rounded transition duration-200 hover:bg-blue-700 ${
                isSidebarOpen ? '' : 'justify-center'
              }`}
            >
              <item.icon size={20} />
              {isSidebarOpen && (
                <span className="ml-4 text-sm">{item.text}</span>
              )}
            </Link>
          ))}
        </nav>
      </div>

      {/* Logout Button */}
      <button 
        onClick={handleLogout} 
        className={`flex items-center py-3 px-4 rounded transition duration-200 hover:bg-blue-700 mb-8 ${
          isSidebarOpen ? '' : 'justify-center'
        }`}
      >
        <LogOut size={20} />
        {isSidebarOpen && <span className="ml-4 text-sm">Logout</span>}
      </button>
    </div>
  );
}

export default AdminSidebar;