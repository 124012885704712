// New file: src/utils/routeUtils.js
export const PUBLIC_ROUTES = [
    '/login',
    '/admin-login',
    '/register',
    '/forgot-password',
    '/reset-password'
  ];
  
  export const isPublicRoute = (path) => {
    return PUBLIC_ROUTES.some(route => path.startsWith(route));
  };
  
  export const requiresAdminAuth = (path) => {
    return path.startsWith('/admin');
  };
  
  export const requiresMemberAuth = (path) => {
    return path.startsWith('/member');
  };