import React, { useEffect, useState,useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation , useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MemberLogin from './components/members/MemberLogin';
import ProtectedRoute from './components/protectedRoutes';
import AdminLogin from './components/admin/AdminLogin';
import Registration from './components/members/Registration';
import AdminPage from './components/admin/AdminPage.js';
import AdminDashboard from './components/admin/AdminDashboard';
import ManageMembers from './components/admin/manage_members/ManageMembers';
import Transactions from './components/admin/transactions/Transactions';
import Trees from './components/admin/trees/Trees';
import Tickets from './components/admin/Tickets';
import GeneralSettings from './components/admin/general_settings/GeneralSettings';
import Payplans from './components/admin/payplans/Payplans';
import MemberPage from './components/members/MemberPage';
import MemberDashboard from './components/members/MemberDashboard';
import MemberTransactions from './components/members/transactions/MemberTransactions';
import Referrals from './components/members/Referrals';
import MemberTrees from './components/members/MemberTrees';
import MemberTickets from './components/members/MemberTickets';
import Profile from './components/members/Profile';
import { AlertProvider } from './contexts/AlertContext';
import authService from './api/auth.js';
import {clearAdmin, clearMember} from './store/authSlice';
import { setLoading } from './store/authSlice';
import RockNRollLoader from './components/common/RockNRollLoader.js';
import {ConfirmationModalProvider} from "./contexts/ConfirmationContext";
import {logger} from "./utils/logger";
import ForgotPassword from "./components/members/ForgotPassword";
import ResetPassword from './components/members/ResetPassword.js';
import ManageEarnings from './components/admin/earnings_management/EarningsManagement.js';
import DocumentHead from './components/common/DocumentHead.js';
import { TokenManager } from './api/utils/tokenManager.js';
import { isPublicRoute } from './utils/routeUtils.js';
import { handleError } from './utils/errorHandler.js';


function AppContent() {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const { loading } = useSelector(state => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const lastAuthRef = useRef({ path: null, timestamp: null });

  // Helper function to determine the login route
  const determineLoginRoute = (path) => {
    return path.startsWith('/admin') ? '/admin-login' : '/login';
  };

  const shouldRevalidateAuth = (currentPath) => {
    // Always revalidate if no previous auth
    if (!lastAuthRef.current.path || !lastAuthRef.current.timestamp) return true;

    const timeSinceLastAuth = Date.now() - lastAuthRef.current.timestamp;
    const MAX_AUTH_AGE = 5 * 60 * 1000; // 5 minutes

    // Revalidate if:
    // 1. Too much time has passed
    // 2. Switching between admin and member sections
    // 3. Coming from a public route to a protected route
    return timeSinceLastAuth > MAX_AUTH_AGE ||
           (currentPath.startsWith('/admin') !== lastAuthRef.current.path.startsWith('/admin')) ||
           (isPublicRoute(lastAuthRef.current.path) && !isPublicRoute(currentPath));
  };

  const handleAuthCleanup = (isAdmin) => {
    if (isAdmin) {
      dispatch(clearAdmin()); // Make sure to import clearAdmin from your auth slice
    } else {
      dispatch(clearMember()); // Make sure to import clearMember from your auth slice
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      // Don't initialize if already loading
      if (loading) return;

      // Special handling for root route
      if (location.pathname === '/') {
        const adminToken = TokenManager.getStoredToken('admin');
        const memberToken = TokenManager.getStoredToken('member');
        
        setIsInitialized(true);
        
        if (adminToken && !TokenManager.isTokenExpired(adminToken, 'admin')) {
          await authService.initializeAuth('/admin/dashboard');
          navigate('/admin/dashboard');
          return;
        }
        
        if (memberToken && !TokenManager.isTokenExpired(memberToken, 'member')) {
          await authService.initializeAuth('/member/dashboard');
          navigate('/member/dashboard');
          return;
        }
        
        navigate('/login');
        return;
      }

      // For public routes, just initialize
      if (isPublicRoute(location.pathname)) {
        setIsInitialized(true);
        return;
      }

      // Check if we need to revalidate auth
      if (!shouldRevalidateAuth(location.pathname)) {
        setIsInitialized(true);
        return;
      }

      dispatch(setLoading(true));
      
      try {
        const isAdminRoute = location.pathname.startsWith('/admin');
        const tokenType = isAdminRoute ? 'admin' : 'member';
        const token = TokenManager.getStoredToken(tokenType);
        
        if (!token || TokenManager.isTokenExpired(token, tokenType)) {
          handleAuthCleanup(isAdminRoute);
          navigate(determineLoginRoute(location.pathname));
          return;
        }

        await authService.initializeAuth(location.pathname);
        
        // Update last successful auth
        lastAuthRef.current = {
          path: location.pathname,
          timestamp: Date.now()
        };
        
        setIsInitialized(true);
      } catch (error) {
        logger.error('Auth initialization error:', error);
        const isAdminRoute = location.pathname.startsWith('/admin');
        handleAuthCleanup(isAdminRoute);
        navigate(determineLoginRoute(location.pathname));
      } finally {
        dispatch(setLoading(false));
      }
    };
    
    initAuth();
  }, [location.pathname]);

  if (loading || !isInitialized) {
    return <RockNRollLoader />;
  }

  // Determine the appropriate login route

  return (
    
    <Routes>
       <Route path="/" element={<RockNRollLoader />} />
      <Route path="/login" element={<MemberLogin />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/admin" element={
        <ProtectedRoute adminOnly={true}>
          <AdminPage />
        </ProtectedRoute>
      }>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="manage-members" element={<ManageMembers />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="manage-earnings" element={<ManageEarnings />} />
        <Route path="manage-earnings/:withdrawalId" element={<ManageEarnings />} />
        <Route path="manage-earnings/:paymentId" element={<ManageEarnings />} />
        <Route path="trees" element={<Trees />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="general-settings" element={<GeneralSettings />} />
        <Route path="payplans" element={<Payplans />} />
      </Route>
      <Route path="/member" element={
        <ProtectedRoute>
          <MemberPage />
        </ProtectedRoute>
      }>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<MemberDashboard />} />
        <Route path="transactions" element={<MemberTransactions />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="tree" element={<MemberTrees />} />
        <Route path="tickets" element={<MemberTickets />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <ConfirmationModalProvider>
      <AlertProvider>
        <Router>
          <DocumentHead />
          <div className="min-h-screen bg-gray-100">
            <AppContent />
          </div>
        </Router>
      </AlertProvider>
    </ConfirmationModalProvider>
  );
}

export default App;