import React, { useState, useEffect } from 'react';
import { Search, Edit, Trash2, Power, UserPlus, LogIn } from 'lucide-react';

import memberService from '../../../api/memberService';
import authService from '../../../api/auth';
import MemberModal from './MemberModal';
import { useAlert } from '../../../hooks/useAlert';

import { useConfirmationModal } from '../../../hooks/useConfirmation';
import CreateTestMembersModal from './CreateTestMembersModal';
import CreateMemberModal from './CreateMemberModal';

import UserCreationButtons from './UserCreationButtons';
import ActivateMemberModal from './ActivateMemberModal';
import DeactivateMemberModal from './DeactivateMemberModal';
import Pagination from '../../common/Pagination';


const ManageMembers = () => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ email: '', username: '', planStatus: '' });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,

    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { showAlert } = useAlert();
  const { openModal } = useConfirmationModal();
  const [isCreateMemberModalOpen, setIsCreateMemberModalOpen] = useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

  const [isDeactivationModalOpen, setIsDeactivationModalOpen] = useState(false);

  const [isTestMembersModalOpen, setIsTestMembersModalOpen] = useState(false);


  useEffect(() => {
    fetchMembers();
  
  }, [pagination.pageNumber, pagination.pageSize]);

  const handlePageSizeChange = (newPageSize) => {
    setPagination(prev => ({ 
      ...prev, 
      pageSize: newPageSize,
      pageNumber: 1 // Reset to first page when changing page size
    }));
  };


  const fetchMembers = async () => {
    setIsLoading(true);
    try {
      const data = await memberService.getMembers(filters, pagination.pageNumber, pagination.pageSize);
      setMembers(data.members);
      setPagination(data.metadata);
    } catch (err) {
      setError(err.message || 'An error occurred while fetching members');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePowerButtonClick = (member) => {
    setSelectedMember(member);
    if (member.planStatus === 'ACTIVE') {
      setIsDeactivationModalOpen(true);
    } else {
      setIsActivationModalOpen(true);
    }
  };

  const handleActivateMember = async (formData) => {
    try {
      await memberService.activateMember(selectedMember.id, formData);
      showAlert('success', 'Member activated successfully');
      await fetchMembers();
     
    } catch (error) {
      showAlert('error', 'Failed to activate member');
      console.error('Error activating member:', error);
    } finally {
      setIsActivationModalOpen(false);
      setSelectedMember(null);
    }
  
  };

  const handleDeactivateMember = async (deactivationData) => {
    try {
      await memberService.deactivateMember(selectedMember.id, {
        effectiveDate: deactivationData.effectiveDate,
        reason: deactivationData.reason
      });
      
      showAlert('success', 'Member deactivated successfully');
      await fetchMembers(); // Refresh the member list after deactivation
    } catch (error) {
      showAlert('error', 'Failed to deactivate member');
      console.error('Error deactivating member:', error);
      throw error; // Re-throw to be caught by the modal's error handling
    } finally {
      setIsDeactivationModalOpen(false);
      setSelectedMember(null);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPagination(prev => ({ ...prev, pageNumber: 1 }));
    fetchMembers();
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({ ...prev, pageNumber: newPage }));
  };

  const handleEditMember = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (member) => {
    openModal(
      'Confirm Deletion',
      `Are you sure you want to delete the member ${member.username}? This action cannot be undone.`,
      async () => {
        try {
          await memberService.deleteMember(member.id);
          showAlert('success', 'Member deleted successfully');
          fetchMembers();
        } catch (error) {
          showAlert('error', 'Failed to delete member');
          console.error('Error deleting member:', error);
        }
      }
    );
  };

  const handleCloseModal = () => {
    setSelectedMember(null);
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleUpdateMember = () => {
    fetchMembers();
    handleCloseModal();
  };

  const handleCreateMember = async (memberData) => {
    try {
      await authService.register(memberData);
      showAlert('success', 'Member created successfully');
      fetchMembers(); // Refresh the member list
    } catch (error) {
      showAlert('error', 'Failed to create member');
      console.error('Error creating member:', error);
    }
    setIsCreateMemberModalOpen(false);
  };

  const handleCreateTestMembers = async (data) => {
    try {
      const result = await memberService.createTestMembers(data.hostUsername, data.baseName, data.numberOfReferrals);
      showAlert('success', `Successfully created ${result.createdMembers.length} test members`);
      fetchMembers(); // Refresh the member list
    } catch (error) {
      showAlert('error', 'Failed to create test members');
      console.error('Error creating test members:', error);
    }
    setIsTestMembersModalOpen(false);
  };

  const handleLoginAsUser = async (member) => {
    try {
      await authService.adminLoginAsUser(member.username);
      showAlert('success', `Opening new window to login as ${member.username}`);
    } catch (error) {
      showAlert('error', 'Failed to login as user');
      console.error('Error logging in as user:', error);
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4 bg-red-100 rounded-lg">{error}</div>;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-2 bg-gradient-to-r from-blue-500 to-indigo-600">
        <h2 className="text-2xl font-bold text-white">Manage Members</h2>
      </div>
      <UserCreationButtons
        onCreateUser={() => setIsCreateMemberModalOpen(true)}
        onCreateTestUsers={() => setIsTestMembersModalOpen(true)}
        showTestUsersButton={process.env.REACT_APP_DEV_ENV === 'true'}
      />

      <form onSubmit={handleSearch} className="p-6 bg-gray-50 border-b border-gray-200">
        <div className="flex flex-wrap items-center -mx-3 mb-4">
          <div className="w-full md:w-1/3 px-3 mb-4 md:mb-0">
            <input
              type="text"
              name="email"
              placeholder="Filter by email"
              value={filters.email}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-4 md:mb-0">
            <input
              type="text"
              name="username"
              placeholder="Filter by username"
              value={filters.username}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="w-full md:w-1/4 px-3 mb-4 md:mb-0">
            <select
              name="planStatus"
              value={filters.planStatus}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Plan Statuses</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="PAYMENT_REPORTED">Payment Reported</option>
            </select>
          </div>
          <div className="w-full md:w-auto px-3">
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full transition duration-300 ease-in-out flex items-center justify-center">
              <Search size={20} />
            </button>
          </div>
        </div>
      </form>

      <Pagination
        pagination={pagination}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User ID</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Username</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Plan Status</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Activation Date</th>
            <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {members.map((member) => (
            <tr key={member.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.id}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <button onClick={() => handleLoginAsUser(member)} className="text-blue-600 hover:text-blue-900">
                  {member.username}
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.email}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  member.planStatus === 'ACTIVE' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {member.planStatus || 'N/A'}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {member.activatedAt? new Date(member.activatedAt).toLocaleDateString(
                  'en-US',
                  { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }
                ) : 'Not Activated'}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex items-center gap-2">
          
                <button 
                  onClick={() => handleEditMember(member)} 
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  <Edit size={18} />
                </button>
                <button 
                  onClick={() => handleDeleteClick(member)} 
                  className="text-red-600 hover:text-red-900"
                >
                  <Trash2 size={18} />
                </button>
                <button 
                    onClick={() => handlePowerButtonClick(member)}
                    className="text-gray-600 hover:text-gray-900"
                  >
                    <Power size={18} 
                      className={member.planStatus === 'ACTIVE' ? 'text-green-500' : 'text-gray-500'} 
                    />
                
                  </button>
                <button 
                  onClick={() => handleLoginAsUser(member)}
                  className="text-blue-600 hover:text-blue-900"
                  title="Login as this user"
                >
                  <LogIn size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <Pagination
        pagination={pagination}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      
      <MemberModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        member={selectedMember}
        onUpdate={handleUpdateMember}
      />

      <CreateTestMembersModal
        isOpen={isTestMembersModalOpen}
        onClose={() => setIsTestMembersModalOpen(false)}
        onSubmit={handleCreateTestMembers}
      />

      <CreateMemberModal
        isOpen={isCreateMemberModalOpen}
        onClose={() => setIsCreateMemberModalOpen(false)}
        onCreateMember={handleCreateMember}
      />
      <ActivateMemberModal
        isOpen={isActivationModalOpen}
        onClose={() => setIsActivationModalOpen(false)}
        onActivateMember={handleActivateMember}
        selectedMember={selectedMember}
      />
       <DeactivateMemberModal
        isOpen={isDeactivationModalOpen}
        onClose={() => {
          setIsDeactivationModalOpen(false);
          setSelectedMember(null);
        }}
        selectedMember={selectedMember}
        onDeactivateMember={handleDeactivateMember}
      />
    </div>
  );
};

export default ManageMembers;