import React, { useState, useEffect } from 'react';
import { Trash2, Plus, Save } from 'lucide-react';
import payTypeService from '../../../api/payTypeService';
import { useAlert } from '../../../hooks/useAlert';
import { useConfirmationModal } from '../../../hooks/useConfirmation';

const PayTypeManagement = () => {
  const [payTypes, setPayTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPayType, setNewPayType] = useState({ name: '', description: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingPayType, setEditingPayType] = useState(null);

  

  const { showAlert } = useAlert(); 
  const { openModal } = useConfirmationModal();

  useEffect(() => {
    fetchPayTypes();
  }, []);

  const fetchPayTypes = async () => {
    try {
      const response = await payTypeService.getPayTypes();
      setPayTypes(response.data.data);
    } catch (error) {
      showAlert('error', 'Failed to load pay types');
    } finally {
      setLoading(false);
    }
  };

  const handleAddPayType = async (e) => {
    e.preventDefault();
    if (!newPayType.name.trim()) {
      showAlert('error', 'Name is required');
      return;
    }
    
    setIsSubmitting(true);
    try {
      const response = await payTypeService.createPayType(newPayType);
      setPayTypes(prevTypes => [...prevTypes, response.data]);
      setNewPayType({ name: '', description: '' });
      showAlert('success', 'Pay type added successfully');
      await fetchPayTypes();
    } catch (error) {
      showAlert('error', 'Failed to add pay type');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdatePayType = async (id, updatedData) => {
    if (!updatedData.name.trim()) {
      showAlert('error', 'Name is required');
      return;
    }

    try {
      await payTypeService.updatePayType(id, updatedData);
      await fetchPayTypes(); // Refresh the list after updating
      setEditingPayType(null);
      showAlert('success', 'Pay type updated successfully');
    } catch (error) {
      showAlert('error', 'Failed to update pay type');
    }
  };

  const handleDeletePayType = (id) => {
    openModal(
      'Delete Pay Type',
      'Are you sure you want to delete this pay type?',
      async () => {
        try {
          await payTypeService.deletePayType(id);
          setPayTypes(prevTypes => prevTypes.filter(pt => pt.id !== id));
          showAlert('success', 'Pay type deleted successfully');
        } catch (error) {
          showAlert('error', 'Failed to delete pay type');
        }
      }
    );
  };

  if (loading) {
    return <div className="animate-pulse bg-white p-6 rounded-lg shadow">
      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
    </div>;
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-6">Payment Types</h2>
      
      <form onSubmit={handleAddPayType} className="mb-6 flex gap-4">
        <input
          type="text"
          placeholder="Name"
          value={newPayType.name}
          onChange={e => setNewPayType(prev => ({...prev, name: e.target.value}))}
          className="flex-1 rounded border border-gray-300 px-3 py-2"
          required
        />
        <input
          type="text"
          placeholder="Description"
          value={newPayType.description}
          onChange={e => setNewPayType(prev => ({...prev, description: e.target.value}))}
          className="flex-1 rounded border border-gray-300 px-3 py-2"
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className={`bg-green-500 text-white px-4 py-2 rounded flex items-center gap-2 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'
          }`}
        >
          <Plus size={18} />
          {isSubmitting ? 'Adding...' : 'Add'}
        </button>
      </form>

      <div className="space-y-4">
        {payTypes.map(payType => (
          <div key={payType.id} className="flex items-center gap-4 p-4 bg-gray-50 rounded">
            {editingPayType === payType.id ? (
              <>
                <input
                  type="text"
                  value={payType.name}
                  onChange={e => setPayTypes(prev => 
                    prev.map(pt => pt.id === payType.id ? {...pt, name: e.target.value} : pt)
                  )}
                  className="flex-1 rounded border border-gray-300 px-3 py-2"
                />
                <input
                  type="text"
                  value={payType.description || ''}
                  onChange={e => setPayTypes(prev => 
                    prev.map(pt => pt.id === payType.id ? {...pt, description: e.target.value} : pt)
                  )}
                  className="flex-1 rounded border border-gray-300 px-3 py-2"
                />
                <button
                  onClick={() => handleUpdatePayType(payType.id, payType)}
                  className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600"
                >
                  <Save size={18} />
                </button>
              </>
            ) : (
              <>
                <div className="flex-1">{payType.name}</div>
                <div className="flex-1">{payType.description}</div>
                <button
                  onClick={() => setEditingPayType(payType.id)}
                  className="text-blue-500 hover:text-blue-700 px-2"
                >
                  Edit
                </button>
              </>
            )}
            <button
              onClick={() => handleDeletePayType(payType.id)}
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 size={18} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PayTypeManagement;