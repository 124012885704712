import React, { useState, useEffect } from 'react';
import paymentsService from '../../../api/paymentsService';
import fileHandlingService from '../../../api/fileHandlingService';
import { X, Maximize, Download, Save, Edit2, XCircle } from 'lucide-react';

const PaymentDetailsModal = ({ isOpen, onClose, payment }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [payTypes, setPayTypes] = useState([]);
  const [editedData, setEditedData] = useState({
    payTypeId: '',
    accountNumber: '',
    bankOrNetwork: '',
    currency: '',
    referenceNumber: '',
    notes: '',
  });
  const [newScreenshot, setNewScreenshot] = useState(null);

  useEffect(() => {
    setPreviewUrl(''); // Clear previous preview
    setNewScreenshot(null); // Clear any new screenshot selection
    if (payment?.screenshot) {
      loadScreenshot();
    }
    loadPayTypes();
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [payment]);

  useEffect(() => {
    if (payment) {
      setEditedData({
        payTypeId: payment.payType.id,
        accountNumber: payment.accountNumber,
        bankOrNetwork: payment.bankOrNetwork,
        currency: payment.currency,
        referenceNumber: payment.referenceNumber,
        notes: payment.notes || '',
      });
    }
  }, [payment]);

  const loadPayTypes = async () => {
    try {
      const types = await paymentsService.getPayTypes();
      setPayTypes(types);
    } catch (err) {
      setError('Failed to load payment types');
    }
  };

  const loadScreenshot = async () => {
    setLoading(true);
    setError(null);
    try {
      const blob = await fileHandlingService.getFileData(payment.screenshot, 'payment');
      const mimeType = fileHandlingService.getMimeType(payment.screenshot);
      const blobWithType = new Blob([blob], { type: mimeType });
      const url = URL.createObjectURL(blobWithType);
      setPreviewUrl(url);
    } catch (err) {
      setError('Failed to load screenshot');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleScreenshotChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!paymentsService.isValidImageFile(file)) {
        setError('Please upload a valid image file');
        return;
      }
      if (!paymentsService.isValidFileSize(file)) {
        setError('File size should be less than 5MB');
        return;
      }
      setNewScreenshot(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      const formData = paymentsService.preparePaymentFormData({
        ...editedData,
        id: payment.id,
        removeScreenshot: !newScreenshot && !previewUrl // Flag to indicate screenshot should be removed
      }, newScreenshot);

      // Add endpoint to handle payment update in paymentsService
      await paymentsService.updatePayment(payment.id, formData);
      setIsEditing(false);
      onClose();
    } catch (err) {
      setError('Failed to update payment details');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen || !payment) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto">
      <div className="min-h-screen px-4 py-6 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Payment Details</h3>
          <div className="flex gap-2">
            {!isEditing && (
              <button
                onClick={() => setIsEditing(true)}
                className="text-blue-600 hover:text-blue-700"
                title="Edit"
              >
                <Edit2 size={20} />
              </button>
            )}
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X size={20} />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">Date</label>
            <p className="text-gray-900">{paymentsService.formatDate(payment.processedAt)}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Amount</label>
            <p className="text-gray-900">{paymentsService.formatAmount(payment.amount)}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Member</label>
            <p className="text-gray-900">{payment.member.username}</p>
            <p className="text-sm text-gray-500">{payment.member.email}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Payment Type</label>
            {isEditing ? (
              <select
                name="payTypeId"
                value={editedData.payTypeId}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              >
                {payTypes.map(type => (
                  <option key={type.id} value={type.id}>{type.name}</option>
                ))}
              </select>
            ) : (
              <p className="text-gray-900">{payment.payType.name}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Account/Wallet</label>
            {isEditing ? (
              <input
                type="text"
                name="accountNumber"
                value={editedData.accountNumber}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              />
            ) : (
              <p className="text-gray-900">{payment.accountNumber}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Bank/Network</label>
            {isEditing ? (
              <input
                type="text"
                name="bankOrNetwork"
                value={editedData.bankOrNetwork}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              />
            ) : (
              <p className="text-gray-900">{payment.bankOrNetwork}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Currency/Token</label>
            {isEditing ? (
              <input
                type="text"
                name="currency"
                value={editedData.currency}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              />
            ) : (
              <p className="text-gray-900">{payment.currency}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Reference Number</label>
            {isEditing ? (
              <input
                type="text"
                name="referenceNumber"
                value={editedData.referenceNumber}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              />
            ) : (
              <p className="text-gray-900">{payment.referenceNumber}</p>
            )}
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-600">Notes</label>
            {isEditing ? (
              <textarea
                name="notes"
                value={editedData.notes}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
                rows="3"
              />
            ) : (
              <p className="text-gray-900">{payment.notes || 'No notes provided'}</p>
            )}
          </div>
        </div>

        {(payment.screenshot || isEditing) && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Payment Screenshot
            </label>
            {isEditing && (
              <div className="flex items-center gap-2 mb-2">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleScreenshotChange}
                  className="flex-grow"
                />
                <button
                  onClick={() => {
                    setPreviewUrl('');
                    setNewScreenshot(null);
                    if (previewUrl) {
                      URL.revokeObjectURL(previewUrl);
                    }
                  }}
                  className="px-3 py-1 bg-red-100 text-red-600 rounded hover:bg-red-200"
                  type="button"
                >
                  Clear Screenshot
                </button>
              </div>
            )}
            {loading ? (
              <div className="flex items-center justify-center h-48 bg-gray-100 rounded">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center h-48 bg-gray-100 rounded text-red-500">
                {error}
              </div>
            ) : (
              <div className="relative group">
                <div className="max-h-96 overflow-hidden">
                  <img
                    src={previewUrl}
                    alt="Payment Screenshot"
                    className="max-w-full h-auto rounded-lg border border-gray-200 cursor-pointer hover:opacity-90 transition-opacity object-contain"
                    onClick={() => !isEditing && fileHandlingService.openFile(payment.screenshot, 'payment')}
                  />
                </div>
                {!isEditing && (
                  <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        fileHandlingService.openFile(payment.screenshot, 'payment');
                      }}
                      className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                      title="Open in new tab"
                    >
                      <Maximize size={16} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        fileHandlingService.downloadFile(payment.screenshot, 'payment');
                      }}
                      className="p-2 rounded bg-black bg-opacity-50 hover:bg-opacity-75 text-white"
                      title="Download"
                    >
                      <Download size={16} />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div className="mt-6 flex justify-end gap-2">
          {isEditing ? (
            <>
              <button
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 flex items-center gap-2"
              >
                <XCircle size={16} />
                Cancel
              </button>
              <button
                onClick={handleSave}
                disabled={loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
              >
                <Save size={16} />
                Save Changes
              </button>
            </>
          ) : (
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default PaymentDetailsModal;