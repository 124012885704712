import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Copy, User, Calendar, Mail, Users, DollarSign, Repeat, Layers, Percent, UserPlus, CreditCard, Clock, CheckCircle2 } from 'lucide-react';
import ReportPaymentForm from './ReportPayment';
import WalletAddressCard from './WalletAddressCard';
import { useAlert } from '../../hooks/useAlert';
import { use } from 'framer-motion/client';

function MemberDashboard() {
  const { member } = useSelector((state) => state.auth);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('plan');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { showAlert } = useAlert();

  const getPaymentStatusCard = () => {
    const statusConfig = {
      INACTIVE: {
        gradient: 'from-orange-500 to-red-600',
        title: 'Activate Your Account',
        icon: CreditCard,
        message: 'Complete your registration by reporting your payment',
        buttonEnabled: true,
        buttonText: 'Report Payment',
        buttonColor: 'bg-orange-500 hover:bg-orange-600'
      },
      PAYMENT_REPORTED: {
        gradient: 'from-yellow-500 to-yellow-600',
        title: 'Payment Under Review',
        icon: Clock,
        message: 'Your payment has been reported and is being verified by our team',
        buttonEnabled: false,
        buttonText: 'Payment Reported',
        buttonColor: 'bg-yellow-500 cursor-not-allowed opacity-75'
      },
      ACTIVE: {
        gradient: 'from-green-500 to-green-600',
        title: 'Account Active',
        icon: CheckCircle2,
        message: 'Your account is active and ready to use',
        buttonEnabled: false,
        buttonText: 'Account Active',
        buttonColor: 'bg-green-500 cursor-not-allowed opacity-75'
      }
    };

    const status = member.currentUser.planStatus;
    const config = statusConfig[status] || statusConfig.INACTIVE;
    const Icon = config.icon;

    return (
      <div className="mb-6">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className={`px-6 py-4 bg-gradient-to-r ${config.gradient}`}>
            <h2 className="text-xl font-semibold text-white flex items-center gap-2">
              <Icon size={24} />
              {config.title}
            </h2>
          </div>
          <div className="p-6">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0">
              {member.currentUser.planStatus === 'ACTIVE' && ( <div className="flex items-center text-gray-700 mb-2">
                  <CreditCard className="mr-2" size={20} />
                  <span className="font-semibold">Registration Fee:</span>
                  <span className="ml-2 text-xl text-orange-600">
                    ${member.currentUser.investments[0]?.amount}
                  </span>
                </div>)}
                <p className="text-gray-600 text-sm">
                  {config.message}
                </p>
              </div> 
              <button
                onClick={() => config.buttonEnabled && setShowPaymentModal(true)}
                disabled={!config.buttonEnabled}
                className={`${config.buttonColor} text-white px-6 py-3 rounded-lg 
                         font-semibold transition duration-300 ease-in-out transform
                         flex items-center space-x-2
                         ${config.buttonEnabled ? 'hover:scale-105' : ''}`}
              >
                <Icon size={20} />
                <span>{config.buttonText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
 

  if (!member) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

 


  const referralLink = `${window.location.origin}/register?ref=${member.currentUser.username}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Welcome, {member.currentUser.username}!</h1>

      <WalletAddressCard />

      {member.currentUser.planStatus !== 'DELETED' && getPaymentStatusCard()}


      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* User Info Card */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-blue-600">
            <h2 className="text-xl font-semibold text-white">Your Details</h2>
          </div>
          <div className="p-6 space-y-4">
            <div className="flex items-center">
              <User className="text-gray-400 mr-3" />
              <span className="text-gray-700">{member.currentUser.username}</span>
            </div>
            <div className="flex items-center">
              <Mail className="text-gray-400 mr-3" />
              <span className="text-gray-700">{member.currentUser.email}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="text-gray-400 mr-3" />
              <span className="text-gray-700">Member since: {new Date(member.currentUser.createdAt).toLocaleDateString()}</span>
            </div>
          </div>
        </div>

        {/* Referral Link Card */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gradient-to-r from-green-500 to-green-600">
            <h2 className="text-xl font-semibold text-white">Your Referral Link</h2>
          </div>
          <div className="p-6">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                value={referralLink}
                readOnly
                className="flex-grow p-2 border rounded text-sm bg-gray-100"
              />
              <button
                onClick={copyToClipboard}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center transition duration-300"
              >
                <Copy size={18} className="mr-2" />
                {copied ? 'Copied!' : 'Copy'}
              </button>
            </div>
            <p className="text-sm text-gray-600">
              Share this link with your friends to earn referral bonuses!
            </p>
          </div>
        </div>

        {/* Host Info Card */}
        {member.currentUser.host && (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-yellow-500 to-yellow-600">
              <h2 className="text-xl font-semibold text-white">Your Host</h2>
            </div>
            <div className="p-6 space-y-4">
              <div className="flex items-center">
                <UserPlus className="text-gray-400 mr-3" />
                <span className="text-gray-700">{member.currentUser.host.username}</span>
              </div>
              <div className="flex items-center">
                <Mail className="text-gray-400 mr-3" />
                <span className="text-gray-700">{member.currentUser.host.email}</span>
              </div>
              <div className="flex items-center">
                <Calendar className="text-gray-400 mr-3" />
                <span className="text-gray-700">Member since: {new Date(member.currentUser.host.createdAt).toLocaleDateString()}</span>
              </div>
            </div>
          </div>
        )}


        {/* Plan and Commission Info Card */}
        {member.currentUser.plan && (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden md:col-span-2">
            <div className="px-6 py-4 bg-gradient-to-r from-purple-500 to-purple-600">
              <h2 className="text-xl font-semibold text-white">Your Subscription</h2>
            </div>
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px">
                <button
                  onClick={() => setActiveTab('plan')}
                  className={`${activeTab === 'plan'
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    } flex-1 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                >
                  Plan Details
                </button>
                <button
                  onClick={() => setActiveTab('commission')}
                  className={`${activeTab === 'commission'
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    } flex-1 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                >
                  Commission Details
                </button>
              </nav>
            </div>
            <div className="p-6">
              {activeTab === 'plan' && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-700 mb-2">{member.currentUser.plan.name}</h3>
                    <div className="flex items-center text-gray-600 mb-2">
                      <Layers className="mr-2" size={18} />
                      Depth: {member.currentUser.plan.depth}
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Users className="mr-2" size={18} />
                      Width: {member.currentUser.plan.width}
                    </div>
                  </div>
                  <div>
                   {/*<div className="flex items-center text-gray-600 mb-2">
                      <DollarSign className="mr-2" size={18} />
                      Registration Fee: ${member.currentUser.plan.registrationFee}
                    </div>*/}
                    <div className="flex items-center text-gray-600 mb-2">
                      <Repeat className="mr-2" size={18} />
                      Renewal Fee: ${member.currentUser.plan.renewalFee}
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Calendar className="mr-2" size={18} />
                      Renewal Period: {member.currentUser.plan.renewalPeriod} months
                    </div>
                  </div>
                </div>
              )}
            {activeTab === 'commission' && member.currentUser.plan?.commissions && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {member.currentUser.plan.commissions
                    .filter(commission => {
                      const percentages = Object.values(commission.percentageByLevel);
                      return percentages.some(percentage => percentage > 0) || commission.selfPayPercentage > 0;
                    })
                    .map(commission => (
                      <div key={commission.id} className="bg-white p-4 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-700 mb-2">
                          {commission.name}
                        </h3>
                        
                        <div className="flex items-center text-gray-600 mb-2">
                          <div className="mr-2">⬇️</div>
                          Depth: {commission.depth}
                        </div>

                        {commission.selfPayPercentage > 0 && (
                          <div className="flex items-center text-gray-600 mb-2">
                            <div className="mr-2">💰</div>
                            Self Pay: {commission.selfPayPercentage}%
                          </div>
                        )}

                        {Object.keys(commission.percentageByLevel).length > 0 && (
                          <div className="mt-3">
                            <h4 className="font-medium text-gray-700 mb-2">Level Percentages:</h4>
                            <div className="grid grid-cols-2 gap-2">
                              {Object.entries(commission.percentageByLevel)
                                .filter(([_, percentage]) => percentage > 0)
                                .map(([level, percentage]) => (
                                  <div 
                                    key={level} 
                                    className="flex items-center justify-between bg-gray-50 p-2 rounded"
                                  >
                                    <span className="text-gray-600">Level {level}:</span>
                                    <span className="font-medium text-gray-700">{percentage}%</span>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        )}

                        {commission.cycleId && (
                          <div className="flex items-center text-gray-600 mt-2">
                            <div className="mr-2">🔄</div>
                            Periods: {commission.numberOfPeriods || 'Continuous'}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        )}

        {showPaymentModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center p-4 border-b">
                <h2 className="text-xl font-semibold">Report Your Payment</h2>
                <button
                  onClick={() => setShowPaymentModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <div className="p-4">
                <ReportPaymentForm
                  userPlanRegFee={member.currentUser.plan?.registrationFee || 0}
                  onSuccess={() => {
                    setShowPaymentModal(false);

                    showAlert('success', 'Payment reported successfully');



                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MemberDashboard;