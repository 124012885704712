import React, { useState } from 'react';
import { X, Upload } from 'lucide-react';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const PaymentModal = ({ 
  memberId,
  isOpen, 
  onClose, 
  onSubmit,
  selectedEarnings = [], 
  setSelectedEarnings,
  payTypes,
  totalWithdrawn, // New prop for maximum available amount
  totalAmount = null // Optional - if provided, we're in "mark as paid" mode
}) => {
  const [formData, setFormData] = useState({
    memberId: memberId,
    payTypeId: '',
    amount: '', // New field for pay by amount
    accountNumber: '',
    bankOrNetwork: '',
    currency: '',
    referenceNumber: '',
    notes: '',
    screenshot: null
  });
  const [error, setError] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);

  const isPayByAmount = !totalAmount; // If totalAmount is null, we're in pay by amount mode

  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value);
    if (isNaN(amount) || amount <= 0) {
      setError('Please enter a valid amount');
    } else if (amount > totalWithdrawn) {
      setError(`Amount cannot exceed total withdrawn balance of $${totalWithdrawn.toFixed(2)}`);
    } else {
      setError('');
    }
    setFormData({ ...formData, amount: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('File size must be less than 5MB');
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError('Only image files are allowed');
        return;
      }
      setFormData({ ...formData, screenshot: file });
      setPreviewUrl(URL.createObjectURL(file));
      setError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validation
    if (!formData.payTypeId) {
      setError('Please select a payment type');
      return;
    }
    if (!formData.referenceNumber) {
      setError('Please enter a reference number');
      return;
    }
    if (isPayByAmount) {
      const amount = parseFloat(formData.amount);
      if (!amount || amount <= 0) {
        setError('Please enter a valid amount');
        return;
      }
      if (amount > totalWithdrawn) {
        setError(`Amount cannot exceed total withdrawn balance of $${totalWithdrawn.toFixed(2)}`);
        return;
      }
    }

    const formDataToSend = new FormData();
    
    // Add memberId
    formDataToSend.append('memberId', memberId);

    // Add appropriate IDs based on mode
    if (isPayByAmount) {
      formDataToSend.append('amount', formData.amount);
    } else {
      formDataToSend.append('earningIds', JSON.stringify(selectedEarnings.map(e => e.id)));
    }

    // Add required fields
    formDataToSend.append('payTypeId', formData.payTypeId);
    formDataToSend.append('referenceNumber', formData.referenceNumber);
    
    // Add optional fields (only if they have values)
    if (formData.accountNumber) {
      formDataToSend.append('accountNumber', formData.accountNumber);
    }
    if (formData.bankOrNetwork) {
      formDataToSend.append('bankOrNetwork', formData.bankOrNetwork);
    }
    if (formData.currency) {
      formDataToSend.append('currency', formData.currency);
    }
    if (formData.notes) {
      formDataToSend.append('notes', formData.notes);
    }

    // Add screenshot if exists
    if (formData.screenshot) {
      formDataToSend.append('screenshot', formData.screenshot);
    }

    // Debug: Log FormData contents
    for (let [key, value] of formDataToSend.entries()) {
      console.log(`${key}: ${value}`);
    }

    onSubmit(formDataToSend);
    setSelectedEarnings([]);
    //clear form
    setFormData({
      memberId: memberId,
      payTypeId: '',
      amount: '',
      accountNumber: '',
      bankOrNetwork: '',
      currency: '',
      referenceNumber: '',
      notes: '',
      screenshot: null
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Process Payment</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X size={20} />
          </button>
        </div>

        <div className="mb-4 p-4 bg-blue-50 rounded-lg">
          <div className="text-sm text-blue-600">
            {isPayByAmount 
              ? 'Processing payment by amount'
              : `Processing payment for ${selectedEarnings.length} selected earning(s)`
            }
          </div>
          <div className="text-lg font-semibold text-blue-800">
            {isPayByAmount
              ? `Maximum Available: $${totalWithdrawn.toFixed(2)}`
              : `Total Amount: $${totalAmount.toFixed(2)}`
            }
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Show amount field only in pay by amount mode */}
          {isPayByAmount && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                step="0.01"
                value={formData.amount}
                onChange={handleAmountChange}
                className="mt-1 w-full rounded-md border border-gray-300 p-2"
                placeholder={`Maximum: $${totalWithdrawn.toFixed(2)}`}
                required
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Payment Type</label>
            <select
              value={formData.payTypeId}
              onChange={(e) => setFormData({ ...formData, payTypeId: e.target.value })}
              className="mt-1 w-full rounded-md border border-gray-300 p-2"
              required
            >
              <option value="">Select Payment Type</option>
              {payTypes.map(type => (
                <option key={type.id} value={type.id}>{type.name}</option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Number/Wallet</label>
              <input
                type="text"
                value={formData.accountNumber}
                onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value })}
                className="mt-1 w-full rounded-md border border-gray-300 p-2"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Bank/Network</label>
              <input
                type="text"
                value={formData.bankOrNetwork}
                onChange={(e) => setFormData({ ...formData, bankOrNetwork: e.target.value })}
                className="mt-1 w-full rounded-md border border-gray-300 p-2"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Currency/Token</label>
              <input
                type="text"
                value={formData.currency}
                onChange={(e) => setFormData({ ...formData, currency: e.target.value })}
                className="mt-1 w-full rounded-md border border-gray-300 p-2"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Reference Number</label>
              <input
                type="text"
                value={formData.referenceNumber}
                onChange={(e) => setFormData({ ...formData, referenceNumber: e.target.value })}
                className="mt-1 w-full rounded-md border border-gray-300 p-2"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              className="mt-1 w-full rounded-md border border-gray-300 p-2"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Payment Screenshot</label>
            <div className="mt-1 flex items-center">
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="hidden"
                id="screenshot-upload"
              />
              <label
                htmlFor="screenshot-upload"
                className="cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <Upload size={20} className="mr-2" />
                Upload Screenshot
              </label>
            </div>
            {previewUrl && (
              <div className="mt-2">
                <img src={previewUrl} alt="Preview" className="max-h-40 rounded-md" />
              </div>
            )}
          </div>

          {error && (
            <div className="p-2 bg-red-50 text-red-600 text-sm rounded">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              disabled={!!error}
            >
              Process Payment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentModal;