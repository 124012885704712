import { useState, useEffect } from 'react';
import fileHandlingService from '../api/fileHandlingService';

export const useLogo = (logoPath) => {
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let mounted = true;

    const loadLogo = async () => {
      if (!logoPath) {
        setLogoUrl(null);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const blob = await fileHandlingService.getFileData(logoPath, 'site');
        const mimeType = fileHandlingService.getMimeType(logoPath);
        const blobWithType = new Blob([blob], { type: mimeType });
        const url = URL.createObjectURL(blobWithType);
        
        if (mounted) {
          setLogoUrl(url);
        }
      } catch (err) {
        console.error('Error loading logo:', err);
        if (mounted) {
          setError('Failed to load logo');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadLogo();

    return () => {
      mounted = false;
      if (logoUrl) {
        URL.revokeObjectURL(logoUrl);
      }
    };
  }, [logoPath]);

  return { logoUrl, loading, error };
};