import React, { useState, useEffect } from 'react';
import earningsService from '../../../api/earningsService';
import { useAlert } from '../../../hooks/useAlert';
import CreateEarningModal from './CreateEarningModal';
import DeductionModal from './DeductionModal';
import payPlansService from '../../../api/payPlansService';
import StatusBadge from './StatusBadge';
import { getStatusTransitionError, getStatusDescription } from '../../../utils/earningsStatusUtils';
import MemberSelector from './MemberSelector';
import Pagination from '../../common/Pagination';
import WithdrawalModal from './WithdrawalModal';
import PaymentModal from './PaymentModal';
import { CreditCard } from 'lucide-react';
import { useConfirmationModal } from '../../../hooks/useConfirmation';
import paymentsService from '../../../api/paymentsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListFilter, Plus, MinusCircle, FileText, Filter, Users, CheckSquare,ArrowUpCircle } from 'lucide-react';
import { startOptimizedAppearAnimation } from 'framer-motion';
import { parse } from 'postcss';


const ManageEarnings = () => {
  const [earnings, setEarnings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeductModal, setShowDeductModal] = useState(false);
  const [showSelectUserModal, setShowSelectUserModal] = useState(false);
  const [commissions, setCommissions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEarnings, setSelectedEarnings] = useState([]);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [hasWithdrawnEarnings, setHasWithdrawnEarnings] = useState(false);
  const { openModal } = useConfirmationModal();
  const [isWithdrawalEarnings, setIsWithdrawalEarnings] = useState(false);
const [withdrawalForm, setWithdrawalForm] = useState({
  amount: '',
});
  const [filters, setFilters] = useState({
    status: 'AVAILABLE',
    dateFrom: '',
    dateTo: '',
    commissionId: '',
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const [earningsStats, setEarningsStats] = useState({
    totalsByStatus: {},
    totalsByCommission: [],
    periodTotals: [],
    summary: {
      totalEarnings: 0,
      availableBalance: 0,
      totalWithdrawn: 0,
      totalDeducted: 0,
      totalPaid: 0,
      onHold: 0,
      scheduled: 0,
      lastProcessedDate: null
    }
  });

  

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentForm, setPaymentForm] = useState({
    payTypeId: '',
    accountNumber: '',
    bankOrNetwork: '',
    currency: '',
    referenceNumber: '',
    notes: '',
    screenshot: null
  });
  const [payTypes, setPayTypes] = useState([]);

  const [createEarningForm, setCreateEarningForm] = useState({
    commissionId: '',
    amount: '',
    scheduledDate: '',
  });

  const [deductionForm, setDeductionForm] = useState({
    amount: '',
    reason: '',
  });

  const [searchQuery, setSearchQuery] = useState('');
const [isSearching, setIsSearching] = useState(false);
const [searchResults, setSearchResults] = useState([]);
const location = useLocation();
const navigate = useNavigate();
const queryParams = new URLSearchParams(location.search);
const withdrawalId = queryParams.get('withdrawalId');
const paymentId = queryParams.get('paymentId');


  const { showAlert } = useAlert();


const [isPaymentEarnings, setIsPaymentEarnings] = useState(false);

// Create a type enum for the earnings view
const EarningsViewType = {
  NORMAL: 'NORMAL',
  WITHDRAWAL: 'WITHDRAWAL',
  PAYMENT: 'PAYMENT'
};

const [earningsViewType, setEarningsViewType] = useState(EarningsViewType.NORMAL);
 

  useEffect(() => {
    if (selectedUser) {
      // Only fetch earnings if in normal view
      if (earningsViewType === EarningsViewType.NORMAL) {
        fetchUserEarnings();
      }

      fetchCommissions();
      fetchEarningsStats();
      fetchPayTypes();
    }
  }, [selectedUser, earningsViewType]);


  useEffect(() => {
    const initializeWithContext = async () => {
      if (!withdrawalId && !paymentId) return;
  
      try {
        setIsLoading(true);
        let contextData;
        let username;
  
        if (withdrawalId) {
          const withdrawal = await earningsService.getWithdrawalById(parseInt(withdrawalId));
          contextData = withdrawal;
          username = withdrawal.member?.username;
          setEarningsViewType(EarningsViewType.WITHDRAWAL);
        } else if (paymentId) {
          const payment = await earningsService.getPaymentById(parseInt(paymentId));
          contextData = payment;
          username = payment.member?.username;
          setEarningsViewType(EarningsViewType.PAYMENT);
        }
  
        if (!contextData || !username) {
          showAlert('error', `Invalid ${withdrawalId ? 'withdrawal' : 'payment'} ID`);
          navigate('/manage-earnings');
          return;
        }
  
        const results = await earningsService.searchMembers(username);
        
        if (!results.members || results.members.length === 0) {
          showAlert('error', 'Member not found');
          navigate('/manage-earnings');
          return;
        }
  
        setSelectedUser(results.members[0]);
        
        // Set earnings and calculate pagination
        const contextEarnings = contextData.earnings;
        setEarnings(contextEarnings);
        
        // Update pagination based on context earnings
        setPagination(prev => ({
          ...prev,
          pageNumber: 1,
          pageSize: 10,
          totalPages: Math.ceil(contextEarnings.length / 10),
          totalCount: contextEarnings.length,
          hasNextPage: contextEarnings.length > 10,
          hasPreviousPage: false
        }));
  
      } catch (error) {
        console.error('Error initializing with context:', error);
        showAlert('error', `Failed to load ${withdrawalId ? 'withdrawal' : 'payment'} information`);
        navigate('/manage-earnings');
      } finally {
        setIsLoading(false);
      }
    };
  
    initializeWithContext();
  }, [withdrawalId, paymentId, navigate]);

  const fetchPayTypes = async () => {
    try {
      const response = await paymentsService.getPayTypes();
      setPayTypes(response);
    } catch (error) {
      console.error('Error fetching pay types:', error);
    }
  };
 
 
  
  // Modify the pagination effect
  useEffect(() => {
    if (selectedUser && earningsViewType === EarningsViewType.NORMAL) {
      fetchUserEarnings();
      fetchEarningsStats();
    }
  }, [pagination.pageNumber, filters, pagination.pageSize, earningsViewType]);

  const handlePaymentSubmit = async (formData) => {

    
    try {
      if (selectedEarnings.length > 0) {
        // Process payment for selected earnings
        await paymentsService.processPaymentByEarnings(formData);
      } else {
        // Process payment by amount
        await paymentsService.processPaymentByAmount(formData);
      }
      
      setShowPaymentModal(false);
      showAlert('success', 'Payment processed successfully');
      
      // Refresh data
      await Promise.all([
        fetchUserEarnings(),
        fetchEarningsStats()
      ]);
    } catch (err) {
      setError('Failed to process payment');
      showAlert('error', 'Failed to process payment');
    }
  };

  const handleWithdrawalByAmount = async (e) => {
    e.preventDefault();
    try {
      if (!earningsService.validateEarningAmount(withdrawalForm.amount)) {
        showAlert('error', 'Please enter a valid amount');
        return;
      }
  
      const withdrawalAmount = parseFloat(withdrawalForm.amount);
      if (withdrawalAmount > earningsStats.summary.availableBalance) {
        showAlert('error', 'Withdrawal amount cannot exceed available balance');
        return;
      }
  
      await earningsService.processWithdrawalByAmount({
        amount: withdrawalForm.amount,
        memberId: selectedUser.id,
      });
      
      setShowWithdrawModal(false);
      setWithdrawalForm({
        amount: '',
      });
      showAlert('success', 'Withdrawal processed successfully');
      
      // Refresh both the displayed earnings and the stats
      await Promise.all([
        fetchUserEarnings(),
        fetchEarningsStats()
      ]);
    } catch (err) {
      setError('Failed to process withdrawal');
      showAlert('error', 'Failed to process withdrawal');
    }
  };

  const fetchEarningsStats = async () => {
    if (!selectedUser) return;
    
    try {
      const stats = await earningsService.getMemberEarningsStats(selectedUser.id);
      setEarningsStats(stats);
      if (stats.summary.totalWithdrawn > 0) {
        setHasWithdrawnEarnings(true);
      }
    } catch (err) {
      setError('Failed to fetch earnings statistics');
      showAlert('error', 'Failed to fetch earnings statistics');
    }
  };


  const clearContextView = () => {
    setEarningsViewType(EarningsViewType.NORMAL);
    setEarnings([]);
    fetchUserEarnings();
  };

useEffect(() => {
  if (selectedUser && earningsViewType === EarningsViewType.NORMAL) {
    fetchUserEarnings();
    fetchEarningsStats();
  }
}, [pagination.pageNumber, filters, pagination.pageSize, earningsViewType]);

  const handlePageSizeChange = (newPageSize) => {
    setPagination(prev => ({
      ...prev,
      pageSize: newPageSize,
      pageNumber: 1 // Reset to first page when changing page size
    }));
  };

  const fetchCommissions = async () => {
    setIsLoading(true);
    try {
      const data = await payPlansService.getCommissions(selectedUser.plan.id);
      setCommissions(data);
    } catch (err) {
      setError('Failed to fetch commissions');
      showAlert('error', 'Failed to fetch commissions');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserEarnings = async () => {
    if (!selectedUser) return;
    
    setIsLoading(true);
    try {
      const data = await earningsService.getAllEarnings({
        ...filters,
        memberId: selectedUser.id,
        page: pagination.pageNumber,
        limit: pagination.pageSize,
        commissionId: filters.commissionId,
      });
      setEarnings(data.earnings);
      
     
      setPagination(data.metadata);
    } catch (err) {
      setError('Failed to fetch earnings');
      showAlert('error', 'Failed to fetch earnings');
    } finally {
      setIsLoading(false);
    }
  };


  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setShowSelectUserModal(false);
  };

  const handleEarningSelection = (earningId) => {
    setSelectedEarnings(prev => {
      if (prev.includes(earningId)) {
        return prev.filter(id => id !== earningId);
      } else {
        return [...prev, earningId];
      }
    });
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedEarnings(earnings.map(e => e.id));
    } else {
      setSelectedEarnings([]);
    }
  };

  const handleBatchStatusChange = async (newStatus) => {
    if (selectedEarnings.length === 0) {
      showAlert('warning', 'Please select earnings to update');
      return;
    }
  
    // Get the selected earnings' full data from the earnings state
    const selectedEarningsData = earnings.filter(e => 
      selectedEarnings.includes(e.id)
    );

    if (newStatus === 'PAID') {
      
      setShowPaymentModal(true);
      return;
    }

    if (newStatus === 'WITHDRAWN') {
      // Return early to prevent further execution
      return new Promise((resolve) => {
        openModal(
          'Confirm Withdrawal',
          'Are you sure you want to withdraw these earnings?',
          async () => {
            try {
              await earningsService.processWithdrawal({
                earningIds: selectedEarnings,
                memberId: selectedUser.id
              });
              showAlert('success', `Successfully processed withdrawal for ${selectedEarnings.length} earnings`);
              setSelectedEarnings([]);
              await fetchUserEarnings();
              resolve();
            } catch (err) {
              setError('Failed to process withdrawal');
              showAlert('error', 'Failed to process withdrawal');
              resolve();
            }
          },
          () => {
            // Handle cancel
            resolve();
          }
        );
      });
    }
  
    // Validate all status transitions before proceeding
    const validationError = getStatusTransitionError(selectedEarningsData, newStatus);
  
    if (validationError.hasError) {
      // Create a detailed error message
      const errorDetails = validationError.details.reduce((acc, error) => {
        const earning = selectedEarningsData.find(e => e.id === error.earningId);
        return acc + `\n• ${earning?.scheduledDate ? new Date(earning.scheduledDate).toLocaleDateString() : 'Unknown date'} - ${error.reason}`;
      }, '');
  
      showAlert('error', 
        <div>
          <p className="font-semibold mb-2">{validationError.message}</p>
          <p className="text-sm mb-2">Target Status: {newStatus} ({getStatusDescription(newStatus)})</p>
          <div className="text-xs whitespace-pre-line">
            {errorDetails}
          </div>
        </div>
      );
      return;
    }
  
    try {
      await earningsService.batchUpdateStatus(selectedEarnings, newStatus);
      showAlert('success', `Successfully updated ${selectedEarnings.length} earnings to ${newStatus.toLowerCase()}`);
      setSelectedEarnings([]); // Clear selections after update
      fetchUserEarnings(); // Refresh the list
    } catch (err) {
      setError('Failed to update earnings status');
      showAlert('error', 'Failed to update status');
    }
  };

  const handleCreateEarning = async (e) => {
    e.preventDefault();
    try {
      if (!earningsService.validateEarningAmount(createEarningForm.amount)) {
        showAlert('error', 'Please enter a valid amount');
        return;
      }
  
      const earningData = {
        ...createEarningForm,
        memberId: selectedUser.id,
        status: new Date(createEarningForm.scheduledDate) <= new Date() 
          ? 'AVAILABLE' 
          : 'SCHEDULED'
      };
  
      // Create the earning
      await earningsService.createManualEarning(earningData);
      
      // Close modal and reset form
      setShowCreateModal(false);
      setCreateEarningForm({
        commissionId: '',
        amount: '',
        scheduledDate: '',
      });
  
      // Add a small delay before fetching updates
      await new Promise(resolve => setTimeout(resolve, 100));
  
      // First fetch earnings stats
      try {
        await fetchEarningsStats();
      } catch (statsError) {
        console.error('Failed to fetch updated stats:', statsError);
      }
  
      // Then fetch user earnings
      try {
        await fetchUserEarnings();
      } catch (earningsError) {
        console.error('Failed to fetch updated earnings:', earningsError);
      }
  
      showAlert('success', 'Manual earning created successfully');
    } catch (err) {
      setError('Failed to create manual earning');
      showAlert('error', 'Failed to create earning');
    }
  };

  const handleDeduction = async (e) => {
    e.preventDefault();
    try {
      if (!earningsService.validateEarningAmount(deductionForm.amount)) {
        showAlert('error', 'Please enter a valid amount');
        return;
      }

      const deductionAmount = parseFloat(deductionForm.amount);
      if (deductionAmount > earningsStats.summary.availableBalance) {
        showAlert('error', 'Deduction amount cannot exceed available balance');
        return;
      }

      await earningsService.processDeduction({
        ...deductionForm,
        memberId: selectedUser.id,
      });
      
      setShowDeductModal(false);
      setDeductionForm({
        amount: '',
        reason: '',
      });
      showAlert('success', 'Deduction processed successfully');
      
      // Refresh both the displayed earnings and the stats
      await Promise.all([
        fetchUserEarnings(),
        fetchEarningsStats()
      ]);
    } catch (err) {
      setError('Failed to process deduction');
      showAlert('error', 'Failed to process deduction');
    }
  };

  const renderActionButtons = () => (
    <div className="flex gap-3 self-end sm:self-auto">
      <button
        onClick={() => setShowCreateModal(true)}
        className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-50 transition-colors flex items-center gap-2 whitespace-nowrap"
      >
        <Plus size={20} />
        Create Earning
      </button>
      <button
        onClick={() => setShowDeductModal(true)}
        className="bg-white text-red-600 px-4 py-2 rounded-lg hover:bg-red-50 transition-colors flex items-center gap-2 whitespace-nowrap"
      >
        <MinusCircle size={20} />
        Process Deduction
      </button>
      <button
        onClick={() => setShowWithdrawModal(true)}
        className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center gap-2 whitespace-nowrap"
      >
        <ArrowUpCircle size={20} />
        Request Withdrawal
      </button>
      {/* Add Pay by Amount button */}
      {selectedUser && hasWithdrawnEarnings && (
        <button
          onClick={() => {
            setSelectedEarnings([]);
            setShowPaymentModal(true);
          }}
          className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors flex items-center gap-2 whitespace-nowrap"
        >
          <CreditCard size={20} />
          Pay by Amount
        </button>
      )}
    </div>
  );

  // Modify batch actions section
  const renderBatchActions = () => {
    if (selectedEarnings.length === 0) return null;

    const allWithdrawn = selectedEarnings.every(id => 
      earnings.find(e => e.id === id)?.status === 'WITHDRAWN'
    );

    const allAvailable = selectedEarnings.every(id => 
      earnings.find(e => e.id === id)?.status === 'AVAILABLE'
    );

    return (
      <div className="bg-blue-50 p-4">
        <div className="flex items-center justify-between">
          <div className="text-sm text-blue-600">
            {selectedEarnings.length} earnings selected
          </div>
          <div className="space-x-2">
            {['AVAILABLE', 'ON_HOLD', 'CANCELLED'].map(status => {
              const validationError = getStatusTransitionError(
                earnings.filter(e => selectedEarnings.includes(e.id)),
                status
              );
              
              return (
                <button
                  key={status}
                  onClick={() => handleBatchStatusChange(status)}
                  className={`px-3 py-1 border rounded transition-colors
                    ${validationError.hasError 
                      ? 'border-gray-300 text-gray-400 cursor-not-allowed'
                      : 'border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white'
                    }`}
                  disabled={validationError.hasError}
                  title={validationError.hasError ? validationError.message : getStatusDescription(status)}
                >
                  Mark as {status.toLowerCase()}
                </button>
              );
            })}
            {allAvailable && (
              <button
                onClick={() => handleBatchStatusChange('WITHDRAWN')}
                className="px-3 py-1 border border-green-500 text-green-500 hover:bg-green-500 hover:text-white rounded transition-colors"
              >
                Mark as withdrawn
              </button>
            )}
            {allWithdrawn && (
              <button
                onClick={() => handleBatchStatusChange('PAID')}
                className="px-3 py-1 border border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white rounded transition-colors"
              >
                Mark as paid
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const EarningsHeader = ({ 
    selectedUser, 
    setSelectedUser, 
    earningsViewType,
    clearContextView,
    withdrawalId,
    paymentId
  }) => {
    const getContextLabel = () => {
      switch (earningsViewType) {
        case EarningsViewType.WITHDRAWAL:
          return {
            icon: <FileText size={14} />,
            text: `Viewing Withdrawal #${withdrawalId}`,
          };
        case EarningsViewType.PAYMENT:
          return {
            icon: <CreditCard size={14} />,
            text: `Viewing Payment #${paymentId}`,
          };
        default:
          return null;
      }
    };
  
    const contextLabel = getContextLabel();
  
    return (
      <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-indigo-600">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="text-white">
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-bold">Manage Earnings</h2>
              <button
                onClick={() => setSelectedUser(null)}
                className="bg-white/20 hover:bg-white/30 text-white px-3 py-1 rounded-lg text-sm flex items-center gap-1 transition-colors"
              >
                <Users size={16} />
                Change User
              </button>
            </div>
            
            <div className="mt-1 space-y-1">
              <p className="text-sm opacity-90">
                User: {selectedUser.username} ({selectedUser.email})
              </p>
              
              {contextLabel && (
                <div className="flex items-center gap-2">
                  <span className="inline-flex items-center gap-1 text-sm bg-white/20 px-2 py-0.5 rounded">
                    {contextLabel.icon}
                    {contextLabel.text}
                  </span>
                  <button
                    onClick={clearContextView}
                    className="text-sm text-white/80 hover:text-white underline underline-offset-2 flex items-center gap-1"
                  >
                    <ListFilter size={14} />
                    View All Earnings
                  </button>
                </div>
              )}
            </div>
          </div>
          {renderActionButtons()}
        </div>
      </div>
    );
  };




  if (!selectedUser) {
    return (
      <MemberSelector
        onSelectMember={handleSelectUser}
        searchMembers={earningsService.searchMembers}
        isSearching={isSearching}
      />
    );
  }

  

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header with user info and actions */}
      <EarningsHeader
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      earningsViewType={earningsViewType}
      clearContextView={clearContextView}
      withdrawalId={withdrawalId}
      paymentId={paymentId}
    />

      
      {earningsViewType === 'NORMAL' && (
        <>
          {/* Stats Section */}
          <div className="p-6 bg-gray-50 border-b">
            {/* Main Stats */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">Available Balance</div>
                <div className="text-2xl font-bold text-blue-600">
                  ${earningsStats.summary.availableBalance.toFixed(2)}
                </div>
              </div>
              
              <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">Total Earnings</div>
                <div className="text-2xl font-bold text-green-600">
                  ${earningsStats.summary.totalEarnings.toFixed(2)}
                </div>
              </div>
            </div>

            {/* Detailed Stats */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">On Hold</div>
                <div className="text-lg font-bold text-orange-600">
                  ${earningsStats.summary.onHold.toFixed(2)}
                </div>
              </div>
              
              <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">Scheduled</div>
                <div className="text-lg font-bold text-yellow-600">
                  ${earningsStats.summary.scheduled.toFixed(2)}
                </div>
              </div>
              
              <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">Withdrawn</div>
                <div className="text-lg font-bold text-green-600">
                  ${earningsStats.summary.totalWithdrawn.toFixed(2)}
                </div>
              </div>


              <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">Paid</div>
                <div className="text-lg font-bold text-purple-600">
                  ${earningsStats.summary.totalPaid.toFixed(2)}
                </div>
              </div>
              
              <div className="bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
                <div className="text-sm text-gray-600">Deducted</div>
                <div className="text-lg font-bold text-red-600">
                  ${earningsStats.summary.totalDeducted.toFixed(2)}
                </div>
              </div>
            </div>
          </div>

          {/* Batch Actions */}
          {renderBatchActions()}

          {/* Filters */}
          <div className="p-4 bg-gray-50 border-b border-gray-200">
            <div className="grid grid-cols-4 gap-4">
              <select
                value={filters.status}
                onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                className="rounded-lg border border-gray-300 p-2"
              >
                <option value="">All Statuses</option>
                <option value="SCHEDULED">Scheduled</option>
                <option value="AVAILABLE">Available</option>
                <option value="WITHDRAWN">Withdrawn</option>
                <option value="PAID">Paid</option>
                <option value="ON_HOLD">On Hold</option>
                <option value="DEDUCTED">Deducted</option>
                <option value="CANCELLED">Cancelled</option>
              </select>

              <select
                value={filters.commissionId}
                onChange={(e) => setFilters(prev => ({ ...prev, commissionId: e.target.value }))}
                className="rounded-lg border border-gray-300 p-2"
                disabled={!commissions || commissions.length === 0}
              >
                <option value="">All Commissions</option>
                {commissions.map(commission => (
                  <option key={commission.id} value={commission.id}>
                    {commission.name}
                  </option>
                ))}
              </select>

              <input
                type="date"
                value={filters.dateFrom}
                onChange={(e) => setFilters(prev => ({ ...prev, dateFrom: e.target.value }))}
                className="rounded-lg border border-gray-300 p-2"
                placeholder="From Date"
              />
              
              <input
                type="date"
                value={filters.dateTo}
                onChange={(e) => setFilters(prev => ({ ...prev, dateTo: e.target.value }))}
                className="rounded-lg border border-gray-300 p-2"
                placeholder="To Date"
              />
            </div>

            {(!commissions || commissions.length === 0) && (
              <div className="mt-2 text-sm text-gray-500">
                Commission filter will be available once commissions are loaded
              </div>
            )}
          </div>
        </>
      )}

      { EarningsViewType != 'NORMAL' && ( <>
        <div className="p-4 bg-gray-50 border-b border-gray-200">
        {renderBatchActions()}
        </div>
      </>
      )}


      {/* Earnings Table */}
      <Pagination
        pagination={pagination}
        onPageChange={(page) => setPagination(prev => ({ ...prev, pageNumber: page }))}
        onPageSizeChange={handlePageSizeChange}
        className="border-b" // for top pagination
      />
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedEarnings.length === earnings.length}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  className="rounded border-gray-300"
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Payer</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Date</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Commission</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {earnings.map((earning) => (
              <tr 
                key={earning.id} 
                className={`hover:bg-gray-50 ${
                  selectedEarnings.includes(earning.id) ? 'bg-blue-50' : ''
                }`}
              >
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedEarnings.includes(earning.id)}
                    onChange={() => handleEarningSelection(earning.id)}
                    className="rounded border-gray-300"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                <div className="flex items-center gap-2">
                  <span>{earning.payer.username}</span>
                </div>
                <div className="invisible group-hover:visible absolute left-0 top-full z-10 w-48 rounded-md bg-gray-900 text-white text-xs p-2 mt-1">
                  {earning.payer.email}
                </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {earningsService.formatDate(earning.scheduledDate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {earningsService.formatAmount(earning.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {earning.commission.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <StatusBadge status={earning.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        pagination={pagination}
        onPageChange={(page) => setPagination(prev => ({ ...prev, pageNumber: page }))}
        onPageSizeChange={handlePageSizeChange}
      />
    
     <CreateEarningModal 
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        commissions={commissions}
        formData={createEarningForm}
        onFormChange={setCreateEarningForm}
        onSubmit={handleCreateEarning}
      />

      <DeductionModal 
        isOpen={showDeductModal}
        onClose={() => setShowDeductModal(false)}
        formData={deductionForm}
        onFormChange={setDeductionForm}
        onSubmit={handleDeduction}
        availableBalance={earningsStats.summary.availableBalance}
      />
      <WithdrawalModal 
        isOpen={showWithdrawModal}
        onClose={() => setShowWithdrawModal(false)}
        formData={withdrawalForm}
        onFormChange={setWithdrawalForm}
        onSubmit={handleWithdrawalByAmount}
        availableBalance={earningsStats.summary.availableBalance}
      />

      <PaymentModal 
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onSubmit={handlePaymentSubmit}
        setSelectedEarnings={setSelectedEarnings}
        selectedEarnings={selectedEarnings.map(id => earnings.find(e => e.id === id))}
        payTypes={payTypes}
        memberId={selectedUser.id}
        totalWithdrawn={earningsStats.summary.totalWithdrawn}
        totalAmount={selectedEarnings.length > 0 
          ? selectedEarnings.reduce((sum, id) => {
              const earning = earnings.find(e => e.id === id);
              return sum + (earning ? earning.amount : 0);
            }, 0)
          : null
        }
      />
   </div>
 );
};

export default ManageEarnings;